@use '../utils' as *;
/*-----------------------------------------------------------------------------------

    Theme Name: Template Name – HTML5 Template
    Author: Theme Pure
    Support: basictheme@gmail.com
    Description: Template Name  – HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. THEME DEFAULT CSS
	02. MEANMENU CSS START
	03. header CSS START
	04. hero CSS START
	05. about CSS START
	06. service CSS START
	07. case CSS START
	08. testimonial CSS START
	09. brand CSS START
	10. blog CSS START
	11. social CSS START
	12. cta CSS START
	13. cart CSS START
	14. team CSS START
	15. price CSS START
	16. 404 CSS START
	17. price CSS START
	18. product CSS START
	19. newsletter CSS START
	20. contact CSS START
	21. footer CSS START

**********************************************/

/*----------------------------------------*/
/*  01. THEME DEFAULT CSS START
/*----------------------------------------*/

@import url($font-url);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
    font-family: var(--tp-ff-body);
    font-size: 14px;
    font-weight: 400;
    color: var(--tp-text-body);
    line-height: 26px;
    overflow-x: hidden;
}
 
html,body{
    overflow-x: hidden;
}

a {
    text-decoration: none;
    transition: .3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--tp-ff-heading);
    color: var(--tp-common-black);
    margin-top: 0px;
    font-weight: 700;
    line-height: 1.1;
    @include transition(.3s);
}

img {
    max-width: 100%;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

ul {
    margin: 0px;
    padding: 0px;
}

p {
    color: var(--tp-text-body);
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
}

.z-index {
    position: relative;
    z-index: 2;
}
.z-index-3 {
    position: relative;
    z-index: 3;
}
.z-index-4 {
    position: relative;
    z-index: 4;
}
.z-index-5 {
    position: relative;
    z-index: 5;
}
.z-index-6 {
    position: relative;
    z-index: 6;
}

a,
.btn,
button,
input,
select,
textarea,
li,
img,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
    @include transition(.3s);
}

li {
    list-style: none;
}

a:focus,
.button:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    color: inherit;
    text-decoration: none;
}

a,
button {
    color: inherit;
    outline: none;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

button:focus {
    outline: 0;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

input,
textarea {
    outline: none;
    color: var(--tp-common-black);
    @include tp-placeholder{
        color: rgba(116, 116, 116, 1);       
    }
}

input[type="color"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 50%;
}

*::-moz-selection {
    background: var(--tp-theme-2);
    color: var(--tp-common-white);
    text-shadow: none;
}

::-moz-selection {
    background: var(--tp-theme-2);
    color: var(--tp-common-white);
    text-shadow: none;
}

::selection {
    background: var(--tp-theme-1);
    color: var(--tp-common-white);
    text-shadow: none;
}


*::-moz-placeholder {
    color: var(--tp-common-black);
    font-size: var(--tp-fz-body);
    opacity: 1;
}

*::placeholder {
    color: var(--tp-common-black);
    font-size: var(--tp-fz-body);
    opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/

.w-img {
    & img {
        width: 100%;
    }
}

.m-img {
    & img {
        max-width: 100%;
    }
}

.fix {
    overflow: hidden
}

.clear {
    clear: both;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.overflow-y-visible {
    overflow-x: hidden;
    overflow-y: visible;
}

.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
}

.include-bg {
    @include background();
}

.gx-10 {
    --bs-gutter-x: 10px;
}
.gx-30 {
    --bs-gutter-x: 30px;
}
.gx-40 {
    --bs-gutter-x: 40px;
}

.gx-50 {
    --bs-gutter-x: 12px;
}
.gx-60 {
    --bs-gutter-x: 65px;
}
.gx-20 {
    --bs-gutter-x: 20px;
}

