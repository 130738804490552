@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Theme Name: Sixart Digital Agency HTML Template
    Author: themeim
    Support: themeim.ticksy.com
    Description: Sixart Digital Agency HTML Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

  01. THEME DEFAULT CSS
	02. MEANMENU CSS START
	03. header CSS START
	04. hero CSS START
	05. about CSS START
	06. service CSS START
	07. case CSS START
	08. testimonial CSS START
	09. brand CSS START
	10. blog CSS START
	11. social CSS START
	12. cta CSS START
	13. cart CSS START
	14. team CSS START
	15. price CSS START
	16. 404 CSS START
	17. price CSS START
	18. product CSS START
	19. newsletter CSS START
	20. contact CSS START
	21. footer CSS START

**********************************************/
/*----------------------------------------*/
/*  01. THEME DEFAULT CSS START
/*----------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800&display=swap");
:root {
  /**
  @font family declaration
  */
  --tp-ff-body: 'Arimo', sans-serif;;
  --tp-ff-heading: 'Poppins', sans-serif;;
  --tp-ff-fontawesome: Font Awesome 5 Pro;
  /**
  @color declaration
  */
  --tp-common-white: #ffffff;
  --tp-common-white-2: #F3F3F3;
  --tp-common-white-3: #F5F5F5;
  --tp-common-black: #151718;
  --tp-common-black-2: #52525C;
  --tp-common-black-3: #1F2021;
  --tp-common-yellow: #EED064;
  --tp-common-yellow-2: #FDFAEF;
  --tp-common-green: #33CC79;
  --tp-common-green-2: #EBFAF2;
  --tp-common-blue: #EEEEFD;
  --tp-common-orange: #FF7425;
  --tp-common-red: #E01E5A;
  --tp-common-sky: #1EDBDC;
  --tp-common-deep-blue: #4C40F7;
  --tp-heading-primary: #151718;
  --tp-grey-1: #F0F0F0;
  --tp-grey-2: #F9F9F9;
  --tp-grey-3: #C2C2C2;
  --tp-grey-4: #DDDDDD;
  --tp-grey-5: #888888;
  --tp-grey-6: #F7F7F7;
  --tp-grey-7: #F6F6F6;
  --tp-text-body: #6A6A74;
  --tp-text-p: #6A6A74;
  --tp-theme-1: #6864ED;
  --tp-border-1: #F5F5F5;
  --tp-border-2: #F4F4F4;
  --tp-border-3: #EDEFEB;
  --tp-border-4: #F1F3F6;
  --tp-border-5: #333435;
  --tp-border-6: #38393A;
  --tp-border-7: #CCD5DA;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
  font-family: var(--tp-ff-body);
  font-size: 14px;
  font-weight: 400;
  color: var(--tp-text-body);
  line-height: 26px;
  overflow-x: hidden;
}

html, body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  transition: 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tp-ff-heading);
  color: var(--tp-common-black);
  margin-top: 0px;
  font-weight: 700;
  line-height: 1.1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

img {
  max-width: 100%;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  color: var(--tp-text-body);
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.z-index {
  position: relative;
  z-index: 2;
}

.z-index-3 {
  position: relative;
  z-index: 3;
}

.z-index-4 {
  position: relative;
  z-index: 4;
}

.z-index-5 {
  position: relative;
  z-index: 5;
}

.z-index-6 {
  position: relative;
  z-index: 6;
}

a,
.btn,
button,
input,
select,
textarea,
li,
img,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

li {
  list-style: none;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input,
textarea {
  outline: none;
  color: var(--tp-common-black);
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgb(116, 116, 116);
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: rgb(116, 116, 116);
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: rgb(116, 116, 116);
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgb(116, 116, 116);
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--tp-theme-2);
  color: var(--tp-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tp-theme-2);
  color: var(--tp-common-white);
  text-shadow: none;
}

::selection {
  background: var(--tp-theme-1);
  color: var(--tp-common-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

*::placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/
.w-img img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.gx-10 {
  --bs-gutter-x: 10px;
}

.gx-30 {
  --bs-gutter-x: 30px;
}

.gx-40 {
  --bs-gutter-x: 40px;
}

.gx-50 {
  --bs-gutter-x: 12px;
}

.gx-60 {
  --bs-gutter-x: 65px;
}

.gx-20 {
  --bs-gutter-x: 20px;
}

.mt-30{
  margin-top:30px;
}

.mt-20{
  margin-top:20px;
}

.mb-5{
  margin-bottom:5px;
}
/*--
    - Spacing
-----------------------------------------*/
.tp-btn {
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-theme-1);
  border-radius: 10px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  padding: 0 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transform: rotate(-0.48deg);
  box-shadow: 0px 9px 14px rgba(89, 86, 233, 0.19);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-btn {
    height: 55px;
    line-height: 55px;
    padding: 0 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-btn {
    height: 55px;
    line-height: 55px;
    padding: 0 30px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .tp-btn {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    font-size: 14px;
  }
}
.tp-btn i {
  padding-left: 17px;
}
.tp-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-green);
  transform: translateY(-4px);
}
.tp-btn:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-btn-sm {
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-common-deep-blue);
  border-radius: 5px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.tp-btn-sm i {
  padding-left: 17px;
}
.tp-btn-sm:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-green);
  transform: translateY(-4px);
}
.tp-btn-sm:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-btn-radius {
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-common-deep-blue);
  border-radius: 10px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  padding: 0 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transform: rotate(-0.48deg);
  border-radius: 96px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-btn-radius {
    height: 55px;
    line-height: 55px;
    padding: 0 30px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-btn-radius {
    height: 55px;
    line-height: 55px;
    padding: 0 30px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .tp-btn-radius {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    font-size: 14px;
  }
}
.tp-btn-radius i {
  padding-left: 17px;
}
.tp-btn-radius:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-green);
}
.tp-btn-radius:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-btn-yellow {
  display: inline-block;
  color: var(--tp-common-black);
  background: var(--tp-common-yellow);
  border-radius: 5px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  padding: 0 40px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  box-shadow: 0px 9px 14px rgba(89, 86, 233, 0.19);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-btn-yellow {
    height: 55px;
    line-height: 55px;
    padding: 0 20px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .tp-btn-yellow {
    height: 55px;
    line-height: 55px;
    padding: 0 40px;
    font-size: 15px;
  }
}
.tp-btn-yellow i {
  padding-left: 5px;
}
.tp-btn-yellow:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-black);
}
.tp-btn-yellow:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-btn-black {
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-common-black);
  border-radius: 5px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  padding: 0 40px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-btn-black {
    height: 55px;
    line-height: 55px;
    padding: 0 20px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .tp-btn-black {
    height: 55px;
    line-height: 55px;
    padding: 0 40px;
    font-size: 15px;
  }
}
.tp-btn-black i {
  padding-left: 7px;
}
.tp-btn-black:hover {
  color: var(--tp-common-white);
  background: var(--tp-theme-1);
}
.tp-btn-black:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-btn-green {
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-common-green);
  border-radius: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0 30px;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
.tp-btn-green:hover {
  color: var(--tp-theme-1);
  background-color: var(--tp-common-white);
}
.tp-btn-green:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-btn-orange {
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-common-orange);
  border-radius: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0 30px;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
.tp-btn-orange:hover {
  color: var(--tp-theme-1);
  background-color: var(--tp-common-white);
}
.tp-btn-orange:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-btn-orange-radius {
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-common-orange);
  border-radius: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0 30px;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
.tp-btn-orange-radius:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-green);
}
.tp-btn-orange-radius:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-btn-radius-2 {
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-theme-1);
  border-radius: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0 30px;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
.tp-btn-radius-2:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-green);
}
.tp-btn-radius-2:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-btn-border {
  display: inline-block;
  color: var(--tp-common-white);
  text-align: center;
  height: 70px;
  line-height: 66px;
  padding: 0 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  border: 2px dashed var(--tp-border-5);
  border-radius: 110px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-btn-border {
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-btn-border {
    height: 55px;
    line-height: 55px;
    padding: 0 30px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .tp-btn-border {
    height: 55px;
    line-height: 55px;
    padding: 0 40px;
    font-size: 15px;
  }
}
.tp-btn-border i {
  padding-left: 17px;
}
.tp-btn-border:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-green);
  border: 2px dashed var(--tp-common-green);
}
.tp-btn-border:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.tp-link a {
  position: relative;
}
.tp-link a svg {
  position: relative;
  z-index: 2;
  margin-right: 20px;
}
.tp-link a::after {
  position: absolute;
  content: "";
  height: 35px;
  width: 35px;
  background-color: rgba(238, 208, 100, 0.15);
  border-radius: 50%;
  transition: 0.5s;
  top: -8px;
  left: 10px;
}
.tp-link a::before {
  position: absolute;
  content: "";
  height: 35px;
  width: 35px;
  background-color: var(--tp-common-yellow);
  border-radius: 50%;
  transition: 0.3s;
  top: -8px;
  left: 10px;
  transform: scale(0.3);
  opacity: 0;
  visibility: hidden;
}
.tp-link a span {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  transition: 0.3s;
}
.tp-link a:hover span {
  color: var(--tp-common-yellow);
}
.tp-link a:hover::before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.tp-link-green a::after {
  background-color: rgba(51, 204, 121, 0.15);
}
.tp-link-green a::before {
  background-color: var(--tp-common-green);
}
.tp-link-green a:hover span {
  color: var(--tp-common-green);
}

.tp-link-border a {
  position: relative;
}
.tp-link-border a span {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.135em;
  text-transform: uppercase;
  margin-right: 15px;
  transition: 0.3s;
  color: var(--tp-common-white);
}
.tp-link-border a svg {
  position: relative;
  color: var(--tp-common-white);
  z-index: 2;
  transition: 0.3s;
}
.tp-link-border a:hover span {
  color: var(--tp-common-green);
}
.tp-link-border a:hover svg {
  color: var(--tp-common-green);
}
.tp-link-border a:hover::before {
  border: 1px dashed var(--tp-common-green);
}
.tp-link-border a::before {
  content: "";
  position: absolute;
  right: -10px;
  top: -6px;
  height: 30px;
  width: 30px;
  border: 1px dashed var(--tp-border-6);
  border-radius: 50%;
  transition: 0.3s;
}

/* pulse effect animation */
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes moving {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes about-circle {
  0% {
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes services-triangle {
  0% {
    -webkit-transform: rotate(0deg) translateX(-50px);
    -moz-transform: rotate(0deg) translateX(-50px);
    -ms-transform: rotate(0deg) translateX(-50px);
    -o-transform: rotate(0deg) translateX(-50px);
    transform: rotate(0deg) translateX(-50px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(100px);
    -moz-transform: rotate(360deg) translateY(100px);
    -ms-transform: rotate(360deg) translateY(100px);
    -o-transform: rotate(360deg) translateY(100px);
    transform: rotate(360deg) translateY(100px);
  }
}
@keyframes hero-3-dot-2 {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes tfLeftToRight {
  49% {
    transform: translateX(30%);
  }
  50% {
    opacity: 0;
    transform: translateX(-30%);
  }
  51% {
    opacity: 1;
  }
}
@keyframes rotate2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes moving {
  0% {
    transform: translatey(0px);
  }
  20% {
    transform: translateX(-50px);
  }
  50% {
    transform: translatey(-40px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes leftright {
  0% {
    transform: translatex(0);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes movinglight {
  0% {
    transform: translatey(0px);
  }
  20% {
    transform: translateX(-70px);
  }
  50% {
    transform: translatey(-70px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes movinglight2 {
  0% {
    transform: translatey(0px);
  }
  20% {
    transform: translateX(70px);
  }
  50% {
    transform: translatey(70px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes movingtop {
  0% {
    transform: translatey(0px);
  }
  20% {
    transform: translatey(70px);
  }
  50% {
    transform: translatey(-70px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes circle-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes circle-animation2 {
  0% {
    transform: scale(-1, 1) rotate(360deg);
  }
  100% {
    transform: scale(-1, 1) rotate(0deg);
  }
}
@keyframes zoom {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
@keyframes zoom2 {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
@keyframes wobble-vertical {
  10% {
    transform: translateY(-10px);
  }
  50% {
    transform: skew(15deg);
  }
  80% {
    transform: rotate(10deg);
  }
  100% {
    transform: translate(0);
  }
}
@-webkit-keyframes section-highlight {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-moz-keyframes section-highlight {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-ms-keyframes section-highlight {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes section-highlight {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes widthcalc {
  0% {
    width: 20%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 90%;
  }
  90% {
    opacity: 75%;
  }
  90% {
    opacity: 100%;
  }
}
/* right bounce */
@-webkit-keyframes bounceRight {
  0%, 20%, 50%, 80%, 100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-90px);
    transform: translateX(-90px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-moz-keyframes bounceRight {
  0%, 20%, 50%, 80%, 100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-90px);
    transform: translateX(-90px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@keyframes bounceRight {
  0%, 20%, 50%, 80%, 100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
  }
  60% {
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
/* /right bounce */
@keyframes icon-bounce {
  0%, 100%, 20%, 50%, 80% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes light-one {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
/*--- preloader ---*/
.dark #preloader {
  background-color: #232323;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 999999;
}

.preloader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.preloader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #53C351;
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite;
}

.preloader span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}
/*--- end of preloader ---*/
/*---------------------------------------
         Background color
-----------------------------------------*/
.grey-bg {
  background: var(--tp-grey-6);
}

.white-bg {
  background: var(--tp-common-white);
}

.black-bg {
  background: var(--tp-common-black);
}

.theme-bg {
  background: var(--tp-theme-1);
}

.body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(24, 24, 24, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: 0.45s ease-in-out;
}
.body-overlay.apply {
  opacity: 1;
  visibility: visible;
}
.body-overlay:hover {
  cursor: url(../img/cross-out.png), pointer;
}

.tp-instagram img {
  width: 100%;
}

.tpoffcanvas {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  right: -100%;
  width: 480px;
  bottom: 0;
  box-shadow: 0 16px -32px 0 rgba(0, 0, 0, 0.8);
  background-color: var(--tp-common-black);
  z-index: 9999;
  padding: 50px;
  scrollbar-width: none;
  opacity: 0;
  visibility: hidden;
  transition: 0.45s ease-in-out;
  overflow-y: scroll;
}
.tpoffcanvas.opened {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .tpoffcanvas {
    width: 300px;
    padding: 40px 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpoffcanvas {
    width: 400px;
    padding: 40px;
  }
}
.tpoffcanvas.opened {
  right: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-duration: 0.6s;
}
.tpoffcanvas__logo {
  margin-bottom: 40px;
}
@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tpoffcanvas__logo {
    padding-top: 0;
  }
}
.tpoffcanvas__logo img {
  width: 140px;
  height: 100%;
}
.tpoffcanvas__close-btn button {
  font-size: 35px;
  color: white;
  position: absolute;
  right: 50px;
  top: 52px;
  transition: 1s;
  font-weight: 300;
  opacity: 0.2;
  -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-duration: 0.6s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpoffcanvas__close-btn button {
    font-size: 28px;
    right: 48px;
    top: 52px;
  }
}
@media (max-width: 767px) {
  .tpoffcanvas__close-btn button {
    font-size: 28px;
    right: 20px;
    top: 52px;
  }
}
.tpoffcanvas__close-btn button:hover {
  color: #fff;
  opacity: 1;
}
.tpoffcanvas__content {
  margin-bottom: 30px;
}
.tpoffcanvas__content p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 25px;
}
.tpoffcanvas__content span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  display: inline-block;
}
.tpoffcanvas__content a {
  font-size: 30px;
  line-height: 51px;
  font-weight: 700;
  color: var(--tp-common-white);
  display: inline-block;
}
@media (max-width: 767px) {
  .tpoffcanvas__content a {
    font-size: 27px;
  }
}
.tpoffcanvas__social {
  margin-top: 50px;
}
.tpoffcanvas__social .social-icon a {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  background-color: var(--tp-common-white);
  color: var(--tp-theme-1);
  margin-right: 15px;
  transition: 0.3s;
  display: inline-block;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .tpoffcanvas__social .social-icon a {
    margin-right: 3px;
  }
}
.tpoffcanvas__social .social-icon a:hover {
  background-color: var(--tp-common-green);
  color: var(--tp-common-white);
}
.tpoffcanvas__text {
  border-bottom: 1px solid #3b3838;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tpoffcanvas__text {
    display: none;
  }
}
.tpoffcanvas__text p {
  color: var(--tp-common-white);
  font-size: 18px;
}
.tpoffcanvas__info {
  border-bottom: 1px solid #3b3838;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.tpoffcanvas__info .offcanva-title {
  color: var(--tp-common-white);
  margin: 30px 0 40px 0;
  font-size: 20px;
  font-weight: 600;
}
.tpoffcanvas__info-icon a {
  height: 50px;
  width: 50px;
  background-color: var(--tp-theme-1);
  color: var(--tp-common-white);
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 18px;
  margin-right: 20px;
}
.tpoffcanvas__info-address span {
  display: block;
  color: var(--tp-common-white);
  font-size: 16px;
  font-weight: 400;
}
.tpoffcanvas__info-address a {
  display: block;
  color: var(--tp-common-white);
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .tpoffcanvas__info-address a {
    font-size: 16px;
  }
}
.tpoffcanvas__info-address a:hover {
  color: var(--tp-theme-1);
}

.mobile-menu.mean-container {
  margin-bottom: 50px;
}

.offcan-social-title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  display: inline-block;
}

.tpoffcanvas-social {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header-border-2.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--tp-common-white);
  width: 100%;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0px 2px 55px rgba(168, 182, 218, 0.32);
  z-index: 999;
}

.header-border-2.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--tp-common-white);
  width: 100%;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0px 10px 21px rgba(0, 0, 0, 0.05);
  z-index: 999;
}

.header-transparent.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
}
.header-transparent.header-sticky.header-border {
  border: none;
}

.black-bg.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #051B46;
  width: 100%;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
}
.black-bg.header-sticky.header-border {
  border: none;
}

.sticky-white-bg.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--tp-common-white);
  width: 100%;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0px 10px 21px rgba(0, 0, 0, 0.05);
  z-index: 999;
}

.tp-header-black-sticky.header-sticky {
  background-color: #2c2c2c;
}

.breadcrumb-height {
  padding-top: 300px;
  padding-bottom: 160px;
}
@media (max-width: 767px) {
  .breadcrumb-height {
    padding-top: 170px;
    padding-bottom: 80px;
  }
}

.breadcrumb__title {
  font-size: 60px;
  line-height: 74px;
  letter-spacing: -0.01em;
  color: var(--tp-common-black);
  padding-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb__title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .breadcrumb__title {
    font-size: 35px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
.breadcrumb__list {
  display: inline-block;
  padding: 0px 10px;
}
.breadcrumb__list span {
  font-size: 16px;
  color: var(--tp-common-black);
  font-weight: 500;
  text-transform: uppercase;
}
.breadcrumb__list span.dvdr i {
  font-size: 18px;
  padding: 0px 5px;
  font-weight: 400;
}
.breadcrumb__subtitle {
  font-size: 18px;
  font-weight: 300;
  color: var(--tp-common-black);
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .breadcrumb__subtitle {
    font-size: 15px;
  }
}

.breadcrumb__area {
  background-size: cover;
  background-position: center;
}

.tp-custom-accordio .accordion-items {
  margin-bottom: 20px;
  border: 1px solid var(--tp-border-1);
  border-radius: 10px;
}
.tp-custom-accordio .accordion-items:not(.collapsed) {
  background-color: var(--tp-grey-6);
}
.tp-custom-accordio .accordion-buttons {
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  padding: 40px 45px;
  color: #212121;
  border-radius: 10px 10px 0 0;
}
@media (max-width: 767px) {
  .tp-custom-accordio .accordion-buttons {
    font-size: 17px;
    padding: 28px 20px;
  }
}
.tp-custom-accordio .accordion-buttons::after {
  position: absolute;
  content: "\f063";
  font-family: "Font Awesome 5 Pro";
  background-image: none;
  top: 45px;
  right: 45px;
  color: #B8BCC8;
  width: auto;
  height: auto;
  font-weight: 700;
  font-size: 16px;
  color: var(--tp-theme-1);
}
@media (max-width: 767px) {
  .tp-custom-accordio .accordion-buttons::after {
    top: 33px;
    right: 18px;
  }
}
.tp-custom-accordio .accordion-buttons.collapsed {
  background-color: var(--tp-common-white);
}
.tp-custom-accordio .accordion-buttons.collapsed::after {
  color: #B8BCC8;
  content: "\f062";
}
.tp-custom-accordio .collapsed {
  border-radius: 10px 10px 10px 10px;
}
.tp-custom-accordio .accordion-body {
  padding: 20px 45px;
  position: relative;
  z-index: 1;
  border-radius: 0 0px 10px 10px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .tp-custom-accordio .accordion-body {
    padding: 20px 25px;
  }
}
.tp-custom-accordio .accordion-body::before {
  position: absolute;
  content: "";
  height: 2px;
  background-color: var(--tp-grey-1);
  top: 0;
  left: 45px;
  right: 45px;
}

.faq-accordio-border .accordion-items {
  background-color: var(--tp-common-white);
  border: 1px solid #F5F5F5;
  border-radius: 10px;
}

.tp-section-subtitle {
  font-family: "Arimo";
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--tp-common-black-2);
  background-color: var(--tp-common-white);
  display: inline-block;
  padding: 10px 20px;
  border-radius: 65px;
  border: 1px solid var(--tp-border-1);
  box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .tp-section-subtitle {
    padding: 10px 13px;
  }
}
.tp-section-subtitle::after {
  content: "";
  height: 12px;
  width: 12px;
  background-color: var(--tp-theme-1);
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
}
.tp-section-subtitle::before {
  content: "";
  height: 12px;
  width: 12px;
  background-color: var(--tp-theme-1);
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}
.tp-section-subtitle.tp-green-color::after {
  background-color: var(--tp-common-green);
}
.tp-section-subtitle.tp-green-color::before {
  background-color: var(--tp-common-green);
}
.tp-section-subtitle.tp-yellow-color::after {
  background-color: var(--tp-common-yellow);
}
.tp-section-subtitle.tp-yellow-color::before {
  background-color: var(--tp-common-yellow);
}

.tp-section-title {
  font-weight: 600;
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -0.01em;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-section-title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-section-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .tp-section-title {
    font-size: 30px;
  }
}

.tp-section-title-sm {
  font-weight: 600;
  font-size: 55px;
  line-height: 1.1;
  letter-spacing: -0.01em;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-section-title-sm {
    font-size: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-section-title-sm {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .tp-section-title-sm {
    font-size: 35px;
  }
}

.tp-section-title-xs {
  font-weight: 600;
  font-size: 45px;
  letter-spacing: -0.01em;
  line-height: 1.2;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-section-title-xs br {
    display: none;
  }
}
@media (max-width: 767px) {
  .tp-section-title-xs {
    font-size: 32px;
  }
  .tp-section-title-xs br {
    display: none;
  }
}

/*----------------------------------------*/
/*  03. MEANMENU CSS START
/*----------------------------------------*/
.mobile-menu.mean-container {
  overflow: hidden;
}
@media (max-width: 767px) {
  .mobile-menu.mean-container {
    margin-bottom: 30px;
  }
}

.mobile-menu .sidebar-list {
  clear: both;
}
.mobile-menu .sidebar-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;
  color: var(--tp-common-white);
}
.mobile-menu .sidebar-list li::after {
  top: 10px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid #c00;
  background-color: transparent;
  content: "";
  display: inline-block;
  position: absolute;
}
.mobile-menu .tp-sidebar-social {
  margin-top: 20px;
}
.mobile-menu .tp-sidebar-social a {
  margin-right: 5px;
  background-color: var(--tp-theme-primary);
  color: #fff;
  display: inline-block;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 14px;
  line-height: 34px;
}

.sidebar-title h3 {
  color: var(--tp-common-white);
}

/* mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 15px 0;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
}
.mean-container .mean-nav ul li a:hover {
  color: var(--tp-theme-1);
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 10px;
  padding: 0 !important;
  line-height: 14px;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #fff;
  line-height: 30px;
  top: 0;
  font-weight: 400;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
  background: var(--clr-theme-1);
  color: var(--tp-theme-1);
  border-color: #888888;
}

.mean-container .mean-nav ul li > a > i {
  display: none;
}

.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
  color: var(--clr-common-black);
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  color: var(--tp-common-white);
}

/*----------------------------------------*/
/*  03. header CSS START
/*----------------------------------------*/
.header-pl-pr {
  padding-left: 60px;
  padding-right: 40px;
}
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .header-pl-pr {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header-pl-pr {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-pl-pr {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-pl-pr {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-pl-pr {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .header-pl-pr {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.header-border-bottom {
  border-bottom: 1px solid var(--tp-border-3);
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-md-space {
    padding: 15px 15px;
  }
}

.tp-logo-border {
  padding: 43px 0;
  padding-right: 80px;
  margin-right: 60px;
  border-right: 1px solid var(--tp-border-3);
}
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .tp-logo-border {
    padding-right: 40px;
    margin-right: 40px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-logo-border {
    padding-right: 20px;
    margin-right: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-logo-border {
    padding-right: 20px;
    margin-right: 0px;
    padding: 33px 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-logo-border {
    padding-right: 20px;
    margin-right: 0px;
    padding: 33px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-logo-border {
    padding: 23px 0;
    padding-right: 0px;
    margin-right: 0px;
  }
}
@media (max-width: 767px) {
  .tp-logo-border {
    padding: 20px 0;
    padding-right: 0px;
    margin-right: 0px;
    border-right: 0;
  }
}

.tp-logo img {
  width: 200px;
}

.tp-main-menu ul {
  text-align: center;
}
.tp-main-menu ul li {
  display: inline-block;
  margin: 0px 50px;
  position: relative;
}
.tp-main-menu ul li:last-child {
  margin-right: 0;
}
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .tp-main-menu ul li {
    margin: 0px 30px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-main-menu ul li {
    margin: 0px 25px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-main-menu ul li {
    margin: 0px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-main-menu ul li {
    margin: 0px 10px;
  }
}
.tp-main-menu ul li a {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  padding: 50px 0px;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-main-menu ul li a {
    font-size: 14px;
    padding: 40px 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-main-menu ul li a {
    font-size: 14px;
    padding: 40px 0px;
  }
}
.tp-main-menu ul li > .submenu {
  position: absolute;
  background-color: #fff;
  width: 270px;
  z-index: 999;
  margin-left: 0;
  padding: 30px 0px;
  top: 110%;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  box-shadow: 0px 8px 20px rgba(61, 110, 168, 0.2);
  text-align: start;
  border-top: 5px solid var(--tp-theme-1);
  border-radius: 5px 5px 0 0;
}
.tp-main-menu ul li > .submenu li {
  margin: 0;
  display: block;
  margin-bottom: 15px;
  padding: 0 40px;
}
.tp-main-menu ul li > .submenu li:last-child {
  margin-bottom: 0;
}
.tp-main-menu ul li > .submenu li a {
  padding: 0;
  margin: 0;
  display: inline-block;
  text-transform: capitalizes;
  font-size: 14px;
  color: var(--tp-common-black);
  position: relative;
  letter-spacing: 1px;
}
.tp-main-menu ul li > .submenu li a::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0;
  left: auto;
  height: 1px;
  width: 0;
  background-color: var(--tp-theme-1);
  transition: 0.7s;
  display: inline-block;
}
.tp-main-menu ul li > .submenu li:hover a {
  color: var(--tp-theme-1);
}
.tp-main-menu ul li > .submenu li:hover a::after {
  width: 100%;
  right: auto;
  left: 0;
}
.tp-main-menu ul li > .submenu li .submenu {
  left: 100%;
  top: 0;
}
.tp-main-menu ul li:hover a {
  color: var(--tp-theme-1);
}
.tp-main-menu ul li:hover > .submenu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-white-menu ul {
    margin-right: -100px;
  }
}
.tp-white-menu ul li {
  margin: 0px 40px;
}
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .tp-white-menu ul li {
    margin: 0px 32px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-white-menu ul li {
    margin: 0px 23px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-white-menu ul li {
    margin: 0px 20px;
  }
}
.tp-white-menu ul li a {
  color: var(--tp-common-white);
  font-size: 14px;
}

.submenu-2 ul li:hover a {
  color: var(--tp-common-orange);
}
.submenu-2 ul li > .submenu {
  border-top: 5px solid var(--tp-common-orange);
}
.submenu-2 ul li > .submenu li a::after {
  background-color: var(--tp-common-orange);
}
.submenu-2 ul li > .submenu li:hover a {
  color: var(--tp-common-orange);
}
.submenu-2 ul li > .submenu li:hover a::after {
  width: 100%;
  right: auto;
  left: 0;
}

.tp-header-right {
  margin-left: 55px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-header-right {
    margin-left: 0px;
  }
}
.tp-header-right ul {
  text-align: end;
}
.tp-header-right ul li {
  display: inline-block;
  padding: 35px 0px;
  padding-left: 45px;
  margin-left: 45px;
  border-left: 1px solid var(--tp-border-3);
  position: relative;
}
.tp-header-right ul li:hover .minicart {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .tp-header-right ul li {
    padding-left: 35px;
    margin-left: 35px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-header-right ul li {
    padding-left: 30px;
    margin-left: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-header-right ul li {
    padding: 25px 0px;
    padding-left: 20px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-header-right ul li {
    padding: 25px 0px;
    padding-left: 15px;
    margin-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-header-right ul li {
    padding: 15px 0px;
    padding-left: 15px;
    margin-left: 15px;
  }
}
@media (max-width: 767px) {
  .tp-header-right ul li {
    padding: 12px 0px;
    padding-left: 15px;
    margin-left: 0px;
    border-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-header-right ul li {
    padding: 12px 15px;
    padding-left: 25px;
  }
}
.tp-header-right ul li > a {
  height: 55px;
  width: 55px;
  background-color: var(--tp-common-white);
  color: var(--tp-common-black);
  display: inline-block;
  text-align: center;
  line-height: 55px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  opacity: 0.76;
  border: 1px solid var(--tp-border-1);
  box-shadow: 0px 6px 12px rgba(74, 74, 74, 0.07);
  border-radius: 8px;
}
.tp-header-right ul li > a i {
  transition: 0.3s;
}
.tp-header-right ul li > a > span {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--tp-common-white);
  height: 20px;
  width: 20px;
  background-color: var(--tp-common-green);
  display: inline-block;
  position: absolute;
  right: -10px;
  top: 33px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 8px rgba(91, 91, 91, 0.25);
  cursor: pointer;
}

.header-right-two > a.tp-search, .minicart-box > a.tp-search, .search-wrapper > a.tp-search {
  color: var(--tp-common-white);
  font-size: 22px;
  font-weight: 600;
}
.header-right-two > a.bars, .minicart-box > a.bars, .search-wrapper > a.bars {
  color: var(--tp-common-white);
  font-size: 22px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .header-right-two > a.bars, .minicart-box > a.bars, .search-wrapper > a.bars {
    margin-left: 0;
    height: 40px;
    width: 40px;
    line-height: 40px;
    background-color: var(--tp-common-orange);
    color: var(--tp-common-white);
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
  }
}
.header-right-two > a.tp-shopping-bskt, .minicart-box > a.tp-shopping-bskt, .search-wrapper > a.tp-shopping-bskt {
  color: var(--tp-common-white);
  font-size: 22px;
  font-weight: 600;
  position: relative;
  padding: 38px 0;
  margin-left: 40px;
}
.header-right-two > a.tp-shopping-bskt span, .minicart-box > a.tp-shopping-bskt span, .search-wrapper > a.tp-shopping-bskt span {
  height: 20px;
  width: 20px;
  background-color: #FF7425;
  display: inline-block;
  border-radius: 50%;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  top: 28px;
  right: -16px;
}
.header-right-two > a.tp-btn-border, .minicart-box > a.tp-btn-border, .search-wrapper > a.tp-btn-border {
  margin-left: 80px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .header-right-two > a.tp-btn-border, .minicart-box > a.tp-btn-border, .search-wrapper > a.tp-btn-border {
    margin-left: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-right-two > a.tp-btn-border, .minicart-box > a.tp-btn-border, .search-wrapper > a.tp-btn-border {
    margin-left: 35px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-two > a.tp-btn-border, .minicart-box > a.tp-btn-border, .search-wrapper > a.tp-btn-border {
    margin-left: 50px;
  }
}

.tp-social-menu ul li {
  display: inline-block;
  margin: 55px 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-social-menu ul li {
    margin: 40px 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-social-menu ul li {
    margin: 40px 20px;
  }
}
.tp-social-menu ul li a {
  padding: 10px 12px;
  border: 2px solid var(--tp-border-1);
  border-radius: 47px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: var(--tp-common-black);
  background-color: var(--tp-common-white);
}
.tp-social-menu ul li a img {
  padding-right: 8px;
}

.header-right-three button {
  height: 55px;
  width: 55px;
  background-color: var(--tp-common-white);
  color: var(--tp-common-black);
  text-align: center;
  line-height: 55px;
  border-radius: 8px;
  font-size: 22px;
  font-weight: 400;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .header-right-three button {
    box-shadow: 0px 6px 12px rgba(74, 74, 74, 0.07);
  }
}

.header-sticky .header-right-three button {
  box-shadow: 0px 6px 12px rgba(74, 74, 74, 0.07);
}

.header-three.header-sticky {
  box-shadow: 0px 6px 12px rgba(74, 74, 74, 0.07);
}

.tp-search-form {
  position: absolute;
  top: 110%;
  right: -50px;
  padding: 40px;
  background-color: black;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
}
.tp-search-form.search-right {
  right: 400px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-search-form.search-right {
    right: 200px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-search-form.search-right {
    right: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-search-form.search-right {
    right: 100px;
  }
}
@media (max-width: 767px) {
  .tp-search-form {
    padding: 20px;
  }
}
.tp-search-form button {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  font-size: 20px;
  color: var(--tp-common-white);
}
.tp-search-form input {
  padding-right: 50px;
  width: 270px;
  margin-bottom: 0;
  color: inherit;
  border: none;
  display: block;
  height: 56px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-width: 0 0 1px 0;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 500;
  color: var(--tp-common-white);
  background-color: transparent;
}
@media (max-width: 767px) {
  .tp-search-form input {
    width: 200px;
    height: 40px;
  }
}
.tp-search-form input::-webkit-input-placeholder {
  color: var(--tp-grey-1);
  font-size: 16px;
}
.tp-search-form input:-moz-placeholder {
  color: var(--tp-grey-1);
  font-size: 16px;
}
.tp-search-form input::-moz-placeholder {
  color: var(--tp-grey-1);
  font-size: 16px;
}
.tp-search-form input:-ms-input-placeholder {
  color: var(--tp-grey-1);
  font-size: 16px;
}
.tp-search-form input:focus::-webkit-input-placeholder {
  color: var(--tp-common-white);
}
.tp-search-form input:focus:-moz-placeholder {
  color: var(--tp-common-white);
}
.tp-search-form input:focus::-moz-placeholder {
  color: var(--tp-common-white);
}
.tp-search-form input:focus:-ms-input-placeholder {
  color: var(--tp-common-white);
}

@media (max-width: 767px) {
  .tp-header-black-sticky {
    padding: 20px 0;
  }
}

.search-open .tp-search-form {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

.tp-search-box {
  position: relative;
}

.tp-search-box .search-close {
  font-weight: 300;
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.3s;
}

.search-open .tp-search-box .search-close {
  transform: translate(-50%, -50%) scale(1);
}

.search-open .tp-search-toggle {
  transition: 0.3s;
  opacity: 0;
  transform: scale(0);
}

.minicart-box:hover .minicart {
  opacity: 1;
  visibility: visible;
}

.minicart {
  background: #fff;
  opacity: 0;
  padding: 25px;
  position: absolute;
  right: -50px;
  top: 110%;
  transition: all 0.3s ease 0s;
  width: 350px;
  z-index: 9;
  box-shadow: 0px 8px 20px rgba(61, 110, 168, 0.3);
  visibility: hidden;
}
@media (max-width: 767px) {
  .minicart {
    right: -69px;
    width: 325px;
    display: none;
  }
}

.checkout-link .tp-btn {
  height: 50px;
  line-height: 50px;
}
.checkout-link .tp-btn-dark-lg {
  height: 50px;
  line-height: 50px;
}

.ede-shop-cart:hover .minicart {
  opacity: 1;
  visibility: visible;
  z-index: 99;
  top: 100%;
}

.minicart .cart-img {
  float: left;
}

.minicart .cart-content {
  float: left;
  padding-left: 15px;
  text-align: left;
}

.minicart .cart-content a {
  color: var(--tp-common-black);
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  font-weight: 700;
}

.minicart .cart-content a:hover {
  color: var(--tp-theme-1);
  background: none;
}

.minicart .cart-img img {
  width: 85px;
}

.minicart .cart-price span {
  color: #ddd;
  font-size: 13px;
}

.minicart .cart-price .new {
  font-size: 14px;
  color: #747691;
}

.minicart .cart-price span {
  color: #a5a7bc;
  font-size: 13px;
  font-weight: 500;
}

.minicart > div {
  display: block;
  margin-bottom: 7px;
  margin-left: 0;
  overflow: hidden;
  padding: 0;
}

.minicart > li:last-child {
  margin-bottom: 0;
}

.minicart .del-icon > a {
  color: var(--tp-common-black);
}

.minicart .del-icon > a:hover {
  color: var(--tp-common-orange);
  background: none;
}

.minicart .del-icon {
  float: right;
  margin-top: 30px;
}

.total-price span {
  color: #747691;
  font-weight: 700;
  font-size: 16px;
}

.total-price {
  border-top: 1px solid #cacadb;
  overflow: hidden;
  padding-top: 25px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.minicart .checkout-link a:last-child {
  margin: 0;
}

.minicart .checkout-link a.red-color:hover {
  background: #84b77c;
}

.header-sep {
  line-height: 1;
  height: 2px;
}

.tp-product-quantity {
  width: 148px;
  position: relative;
}

.tp-cart-plus,
.tp-cart-minus {
  width: 45px;
  height: 44px;
  line-height: 44px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  color: var(--tp-common-black);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.tp-cart-plus::after,
.tp-cart-minus::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 26px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  background-color: #DADCE0;
}
.tp-cart-plus svg,
.tp-cart-minus svg {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}
.tp-cart-plus:hover,
.tp-cart-minus:hover {
  cursor: pointer;
  color: var(--tp-theme-1);
}
.tp-cart-plus.tp-cart-plus,
.tp-cart-minus.tp-cart-plus {
  left: auto;
  right: 0;
}
.tp-cart-plus.tp-cart-plus::after,
.tp-cart-minus.tp-cart-plus::after {
  left: 0;
  right: auto;
}

.tp-cart-input[type=text] {
  height: 44px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #DADCE0;
  background-color: var(--tp-common-white);
  padding: 0 45px;
}
.tp-cart-input[type=text]:focus {
  outline: none;
}

.header-sticky .tp-social-menu ul li {
  margin: 30px 25px;
}

/*----------------------------------------*/
/*  04. hero CSS START
/*----------------------------------------*/
.hero-grideant-bg {
  padding-top: 220px;
  padding-bottom: 90px;
  background-image: url('../img/slider/slider-grident-bg.png');
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .hero-grideant-bg {
    padding-top: 170px;
  }
}

.hero-space {
  padding-bottom: 115px;
}
@media (max-width: 767px) {
  .hero-space {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero {
    margin-bottom: 70px;
  }
}
.tp-hero__section-box .tp-slider-title {
  padding-bottom: 15px;
}
.tp-hero__section-box p {
  font-size: 20px;
  line-height: 32px;
  color: var(--tp-common-black-2);
  padding-bottom: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-hero__section-box p {
    font-size: 17px;
    line-height: 26px;
  }
}
@media (max-width: 767px) {
  .tp-hero__section-box p {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 767px) {
  .tp-hero__wrapper {
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
}
.tp-hero__button {
  margin-right: 50px;
  line-height: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-hero__button {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero__button {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .tp-hero__button {
    margin-bottom: 20px;
    margin-right: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-hero__button {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .tp-hero__play-button {
    margin-bottom: 20px;
  }
}
.tp-hero__play-button a {
  font-weight: 900;
  font-size: 16px;
  height: 70px;
  width: 70px;
  background-color: var(--tp-common-yellow);
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  display: inline-block;
  color: var(--tp-common-white);
  transition: 0.3s;
  margin-right: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero__play-button a {
    font-size: 13px;
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .tp-hero__play-button a {
    font-size: 14px;
    height: 55px;
    width: 55px;
    line-height: 55px;
  }
}
.tp-hero__play-button a:hover {
  background-color: var(--tp-theme-1);
}
.tp-hero__play-button span {
  font-weight: 700;
  font-size: 16px;
  line-height: 64px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--tp-common-black);
}
.tp-hero__thumb {
  mix-blend-mode: luminosity;
}
.tp-hero__thumb img {
  color: var(--tp-common-black);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero__thumb img {
    width: 100%;
  }
}
.tp-hero__thumb3 {
  margin-top: 30px;
  margin-right: -150px;
  margin-bottom: 55px;
}
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .tp-hero__thumb3 {
    margin-right: -90px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-hero__thumb3 {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-hero__thumb3 {
    margin-right: 0px;
    margin-top: 0;
  }
}
.tp-hero__thumb3-shape-1 {
  position: absolute;
  top: 35%;
  left: -11%;
  z-index: -1;
}
.tp-hero__thumb3-shape-2 {
  position: absolute;
  top: 19%;
  right: -4%;
  animation: jumpTwo 4s infinite linear;
}

.tp-slider-title {
  font-weight: 600;
  font-size: 85px;
  line-height: 1.1;
  letter-spacing: -0.01em;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-slider-title {
    font-size: 65px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-slider-title {
    font-size: 55px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-slider-title {
    font-size: 65px;
  }
}
@media (max-width: 767px) {
  .tp-slider-title {
    font-size: 37px;
  }
}

.tp-slider-sm-title {
  font-weight: 600;
  font-size: 66px;
  letter-spacing: -0.01em;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-slider-sm-title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-slider-sm-title {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .tp-slider-sm-title {
    font-size: 34px;
  }
}

.tp-hero-shape-one {
  position: absolute;
  top: 5px;
  right: 20px;
  z-index: 3;
  animation: jumpTwo 4s infinite linear;
}

.tp-hero-shape-two {
  position: absolute;
  bottom: 112px;
  right: 28px;
  z-index: 3;
}

.tp-hero-shape-four {
  position: absolute;
  right: 0;
  animation: rotate2 5s linear infinite;
}

.tp-hero-shape-three {
  position: absolute;
  bottom: 95px;
  left: -60px;
  z-index: 3;
  animation: moving 5s ease-in-out infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-hero-shape-three {
    bottom: 40px;
    left: -103px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-hero-shape-three {
    bottom: 40px;
    left: -130px;
  }
}

.tp-slider-exp-title {
  font-weight: 600;
  font-size: 32px;
  letter-spacing: -0.01em;
  padding-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-slider-exp-title {
    padding-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .tp-slider-exp-title {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-slider-exprience {
    margin-bottom: 60px;
  }
}
.tp-slider-exprience__content {
  padding-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-slider-exprience__content {
    padding-bottom: 5px;
  }
}
.tp-slider-exprience__content p {
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--tp-common-black-2);
  padding-right: 140px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-slider-exprience__content p {
    padding-right: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-slider-exprience__content p {
    padding-right: 45px;
  }
}
@media (max-width: 767px) {
  .tp-slider-exprience__content p {
    padding-right: 0px;
    font-size: 17px;
    line-height: 28px;
  }
}
.tp-slider-exprience__img {
  margin-right: 15px;
  flex: 0 0 auto;
}
@media (max-width: 767px) {
  .tp-slider-exprience__img {
    margin-right: 7px;
  }
}
.tp-slider-exprience__meta span {
  font-weight: 400;
  font-size: 14px;
  color: var(--tp-common-black-2);
}
@media (max-width: 767px) {
  .tp-slider-exprience__meta span {
    font-size: 13px;
  }
}
.tp-slider-exprience__meta span i {
  color: var(--tp-common-yellow);
}

.tp-client-name {
  font-weight: 600;
  font-size: 20px;
}

.tp-slider-exp-item {
  background-color: var(--tp-common-white);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-slider-exp-item {
    padding: 28px;
  }
}
.tp-slider-exp-item__icon {
  height: 50px;
  width: 50px;
  background-color: var(--tp-common-yellow);
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  margin-bottom: 20px;
}
.tp-slider-exp-item__icon svg {
  color: var(--tp-common-white);
}
.tp-slider-exp-item__icon.icon-bg-green {
  background-color: var(--tp-common-green);
}

.tp-exp-sm-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: var(--tp-common-black);
  padding-bottom: 20px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-exp-sm-title br {
    display: none;
  }
}

.hero-bg-img {
  padding-top: 280px;
  padding-bottom: 130px;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .hero-bg-img {
    padding-top: 170px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-bg-img {
    padding-top: 180px;
  }
}

.hero-overlay {
  position: relative;
}
.hero-overlay::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #191B1D;
  opacity: 0.7;
}

.hero-section-title-box h3 {
  margin-bottom: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-section-title-box h3 br {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-section-title-box h3 {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .hero-section-title-box h3 {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-section-title-box h3 {
    margin-bottom: 40px;
  }
}
.hero-section-title-box h3 span img {
  border-radius: 106px;
}

@media (max-width: 767px) {
  .tp-hero-content {
    flex-wrap: wrap;
  }
}
.tp-hero-content__thumb {
  padding-left: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero-content__thumb {
    padding-left: 0;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .tp-hero-content__thumb {
    padding-left: 0;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-hero-content__thumb {
    padding-left: 0;
    margin-bottom: 40px;
    width: 100%;
  }
}
.tp-hero-content__thumb img {
  border-radius: 106px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-hero-content__thumb img {
    width: 100%;
  }
}
.tp-hero-content__text {
  padding-left: 20px;
  padding-right: 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-hero-content__text {
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero-content__text {
    padding-right: 0;
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .tp-hero-content__text {
    padding-right: 0;
    padding-left: 0px;
  }
}
.tp-hero-content__text p {
  font-weight: 400;
  font-size: 26px;
  line-height: 44px;
  color: var(--tp-common-white);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-hero-content__text p {
    font-size: 20px;
    line-height: 33px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-hero-content__text p {
    font-size: 17px;
    line-height: 29px;
  }
}
@media (max-width: 767px) {
  .tp-hero-content__text p {
    font-size: 20px;
    line-height: 28px;
  }
}

.scroll-down .circle-1, .scroll-down .circle-2, .scroll-down .circle-3 {
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 8px;
}
.scroll-down .circle-1 {
  height: 15px;
  width: 15px;
  background-color: rgba(255, 255, 255, 0.1);
}
.scroll-down .circle-2 {
  height: 9px;
  width: 9px;
  background-color: rgba(255, 255, 255, 0.4);
}
.scroll-down .circle-3 {
  height: 5px;
  width: 5px;
  background-color: white;
}
.scroll-down > span {
  margin-top: 2px;
  display: inline-block;
}
.scroll-down > span i {
  font-size: 35px;
  color: var(--tp-common-orange);
  transform: rotate(180deg);
}

.tp-hero-blue-shape {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 480px;
  background-color: var(--tp-theme-1);
  z-index: -1;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-hero-blue-shape {
    width: 380px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-hero-blue-shape {
    width: 340px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-hero-blue-shape {
    width: 385px;
  }
}

.review-wrapper {
  padding: 20px 15px;
  display: inline-block;
  background-color: var(--tp-common-white);
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 12px;
  position: absolute;
  top: 10%;
  left: -10%;
  animation: jumpTwo 4s infinite linear;
}

.review-wrapper-2 {
  padding: 20px 15px;
  display: inline-block;
  background-color: var(--tp-common-white);
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 12px;
  position: absolute;
  bottom: 10%;
  left: -10%;
  animation: about-circle 5s linear infinite alternate;
}

.reviews-text span {
  font-weight: 700;
  font-size: 14px;
  color: var(--tp-common-black);
}
.reviews-text p {
  font-weight: 500;
  font-size: 12px;
  color: #818181;
}
.reviews-text p span {
  color: var(--tp-common-black);
}
.reviews-text p i {
  color: var(--tp-common-yellow);
}

.hero-space-three {
  padding-top: 140px;
}

.hero-thumb-space {
  margin-bottom: 100px;
}

/*----------------------------------------*/
/*  05. about CSS START
/*----------------------------------------*/
.tp-ab-section-title-box {
  padding-left: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-ab-section-title-box {
    padding-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-ab-section-title-box {
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .tp-ab-section-title-box {
    padding-left: 0px;
  }
}
.tp-ab-section-title-box .tp-section-title {
  padding-bottom: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-ab-section-title-box .tp-section-title {
    padding-bottom: 15px;
  }
}
.tp-ab-section-title-box p {
  padding-bottom: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-ab-section-title-box p br {
    display: none;
  }
}
@media (max-width: 767px) {
  .tp-ab-section-title-box p {
    font-size: 17px;
    line-height: 26px;
  }
  .tp-ab-section-title-box p br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-ab-section-title-box p {
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 10px;
  }
  .tp-ab-section-title-box p br {
    display: none;
  }
}

.tp-ab-shape-one {
  position: absolute;
  top: 37px;
  left: 16px;
  animation: jumpTwo 4s infinite linear;
}

.tp-ab-shape-two {
  position: absolute;
  bottom: 102px;
  right: 72px;
}

.tp-about-thumb {
  mix-blend-mode: luminosity;
}
.tp-about-thumb img {
  background-color: #000000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-thumb img {
    width: 100%;
    margin-top: 100px;
  }
}
@media (max-width: 767px) {
  .tp-about-thumb img {
    margin-top: 70px;
    padding-bottom: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-about-thumb img {
    margin-top: 100px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 767px) {
  .ab-area-sapce {
    padding-top: 0;
  }
}

.ab-title-xs {
  font-weight: 600;
  font-size: 36px;
  letter-spacing: -0.01em;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .ab-title-xs {
    font-size: 30px;
  }
  .ab-title-xs br {
    display: none;
  }
}

.ab-inner-img img {
  border-radius: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .ab-inner-content {
    margin-bottom: 60px;
  }
}
.ab-inner-content p {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #6A6A74;
  padding-right: 40px;
  padding-bottom: 20px;
}

.tp-inner-list ul li {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--tp-common-black);
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}
.tp-inner-list ul li:last-child {
  margin-bottom: 0;
}
.tp-inner-list ul li i {
  color: var(--tp-theme-1);
  position: absolute;
  top: 4px;
  left: 0;
  font-weight: 600;
}

.counter-item h4 {
  font-weight: 600;
  font-size: 65px;
  color: transparent;
  -webkit-text-stroke: 1px #000;
  display: inline-block;
  position: relative;
}
.counter-item h4::before {
  content: "";
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #A59FFB;
  z-index: -1;
  position: absolute;
  top: 10px;
  left: 0;
}
.counter-item h3 {
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.am-fea-space {
  padding-left: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .am-fea-space {
    padding-left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .am-fea-space {
    padding-left: 0px;
  }
}

.am-fea-space-2 {
  padding-left: 75px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .am-fea-space-2 {
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .am-fea-space-2 {
    padding-left: 0px;
  }
}

.actestimonial {
  padding-right: 45px;
}
.actestimonial__icon {
  margin-bottom: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .actestimonial__icon {
    margin-bottom: 20px;
  }
}
.actestimonial__position {
  margin-bottom: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .actestimonial__position {
    margin-bottom: 20px;
  }
}
.actestimonial__position span {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.actestimonial__paragraph p {
  font-weight: 400;
  font-size: 26px;
  color: var(--tp-common-black);
  line-height: 1.6;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .actestimonial__paragraph p {
    font-size: 21px;
  }
}
@media (max-width: 767px) {
  .actestimonial__paragraph p {
    font-size: 16px;
    margin-bottom: 50px;
  }
}
.actestimonial__paragraph p span {
  color: var(--tp-common-blue);
  font-size: 23px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .actestimonial__paragraph p span {
    font-size: 20px;
  }
}

.ac-client-name {
  font-size: 24px;
  color: var(--tp-common-black);
  font-weight: 700;
}

.am-p-space-1 {
  padding-bottom: 25px;
  padding-right: 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .am-p-space-1 {
    padding-right: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .am-p-space-1 {
    padding-right: 0px;
  }
}

.am-p-space-2 {
  padding-bottom: 25px;
  padding-right: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .am-p-space-2 {
    padding-right: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .am-p-space-2 {
    padding-right: 0px;
  }
}

.am-p-space-3 {
  padding-bottom: 25px;
  padding-right: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .am-p-space-3 {
    padding-right: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .am-p-space-3 {
    padding-right: 0px;
  }
}

.ac-ab-img {
  width: 100%;
  border-radius: 15px;
}
.ac-ab-img img {
  border-radius: 15px;
  width: 100%;
  transform: scale(1);
  transition: 1s;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .ac-ab-img img {
    width: 100%;
  }
}
.ac-ab-img:hover img {
  transform: scale(1.1);
}

.tp-inner-play-button a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 105px;
  width: 105px;
  background-color: var(--tp-theme-1);
  text-align: center;
  line-height: 105px;
  color: var(--tp-common-white);
  border-radius: 50%;
  font-size: 20px;
  transition: 0.3s;
  animation: pulse 2s infinite;
}
@media (max-width: 767px) {
  .tp-inner-play-button a {
    height: 70px;
    width: 70px;
    line-height: 74px;
    font-size: 12px;
  }
}
.tp-inner-play-button a:hover {
  background-color: var(--tp-common-yellow);
}

.ab-info-space {
  padding-top: 120px;
}
@media (max-width: 767px) {
  .ab-info-space {
    padding-top: 70px;
  }
}

.amaboutinfo__client-info h4 {
  font-size: 30px;
  color: var(--tp-common-black);
  font-weight: 500;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .amaboutinfo__client-info h4 {
    font-size: 24px;
  }
}
.amaboutinfo__client-info span {
  font-size: 12px;
  color: var(--tp-theme-1);
  text-transform: uppercase;
  padding-bottom: 25px;
  display: inline-block;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .amaboutinfo__client-info span {
    padding-bottom: 5px;
  }
}
.amaboutinfo__client-info p {
  color: #8A90A2;
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 25px;
  padding-right: 81px;
  line-height: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .amaboutinfo__client-info p {
    padding-right: 0px;
  }
}
.amaboutinfo__experience p {
  font-size: 16px;
  font-weight: 400;
  color: #8A90A2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .amaboutinfo__experience p {
    font-size: 14px;
  }
}
.amaboutinfo__experience p:hover a {
  color: var(--tp-theme-1);
}
.amaboutinfo__experience p b {
  color: var(--tp-common-black);
}

.amaboutsocial__icon {
  overflow: hidden;
}
@media (max-width: 767px) {
  .amaboutsocial__icon {
    display: inline-block;
    margin-bottom: 0;
  }
}
.amaboutsocial__icon > a {
  display: inline-block;
}
.amaboutsocial__icon .tp-si.tp-si-color-2 {
  background-color: var(--tp-common-blue);
}
.amaboutsocial__icon .tp-si__text {
  background-color: #4267B2;
  height: 42px;
  padding-left: 16px;
  padding-right: 28px;
  display: inline-block;
  color: var(--tp-common-white);
  font-size: 16px;
  font-weight: 500;
  line-height: 42px;
  border-radius: 3px 0 0 3px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  margin-right: -112px;
  z-index: -1;
  position: relative;
}
@media (max-width: 767px) {
  .amaboutsocial__icon .tp-si__text {
    display: none;
  }
}
.amaboutsocial__icon .tp-si__text.tp-si-color-1 {
  background-color: #E40028;
}
.amaboutsocial__icon .tp-si__text.tp-si-color-2 {
  background-color: var(--tp-theme-1);
}
.amaboutsocial__icon .tp-si__text.tp-si-color-3 {
  background-color: var(--tp-common-blue);
}
.amaboutsocial__icon .tp-si__text.tp-si-color-4 {
  background-color: #E40028;
}
.amaboutsocial__icon .tp-si__text::after {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  height: 1px;
  width: 14px;
  background-color: rgba(255, 255, 255, 0.15);
  display: inline-block;
  z-index: 9;
}
.amaboutsocial__icon .tp-si__icon {
  background-color: #4267B2;
  height: 42px;
  width: 42px;
  text-align: center;
  display: inline-block;
  color: var(--tp-common-white);
  line-height: 42px;
  border-radius: 3px;
}
@media (max-width: 767px) {
  .amaboutsocial__icon .tp-si__icon {
    margin-right: 10px;
    margin-top: 20px;
  }
}
.amaboutsocial__icon .tp-si__icon.tp-si-color-1 {
  background-color: #E40028;
}
.amaboutsocial__icon .tp-si__icon.tp-si-color-2 {
  background-color: var(--tp-theme-1);
}
.amaboutsocial__icon .tp-si__icon.tp-si-color-3 {
  background-color: var(--tp-common-blue);
}
.amaboutsocial__icon .tp-si__icon.tp-si-color-4 {
  background-color: #E40028;
}

.si-btn-link:hover .tp-si__text {
  opacity: 1;
  visibility: visible;
  margin-right: 0px;
}
.si-btn-link:hover .tp-si__icon {
  border-radius: 0 3px 3px 0;
}

.feature-bottom-space {
  padding-bottom: 90px;
}
@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-bottom-space {
    padding-bottom: 90px;
  }
}

.amfeature {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .amfeature {
    padding-left: 0px;
  }
}
.amfeature__item p {
  font-size: 15px;
  line-height: 26px;
}
.amfeature__title p {
  font-size: 16px;
}
.amfeature__list ul li {
  color: var(--tp-common-black);
  font-size: 15px;
  font-weight: 500;
  position: relative;
  padding-left: 30px;
  padding-bottom: 5px;
}
.amfeature__list ul li i {
  color: #726FFC;
  font-weight: 500;
  position: absolute;
  left: 0;
  top: 6px;
}

.amskill__title p {
  font-size: 16px;
  line-height: 26px;
}

.am-skill-title {
  font-size: 30px;
  color: var(--tp-common-black);
  font-weight: 500;
  padding-bottom: 25px;
}

.am-skill-sm-title {
  font-size: 24px;
  font-weight: 500;
  color: var(--tp-common-black);
  padding-bottom: 20px;
}

.am-contact-title {
  font-size: 24px;
  color: var(--tp-common-black);
  font-weight: 500;
  position: relative;
  overflow: hidden;
  background-color: var(--tp-common-white);
  padding-right: 90px;
  display: inline-block;
  margin-bottom: 0;
  line-height: 1.3;
}
@media (max-width: 767px) {
  .am-contact-title {
    font-size: 23px;
  }
}

.am-border-shape {
  position: absolute;
  height: 1px;
  background-color: #F4F6FB;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .am-about-area {
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .am-about-area {
    padding-bottom: 0;
    padding-top: 70px;
  }
}

.contact-form {
  background-color: var(--tp-grey-6);
  border-radius: 20px;
  padding: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .contact-form {
    padding: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-form {
    padding: 30px;
  }
}
.contact-form .input {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .contact-form .input {
    margin-bottom: 10px;
  }
}
.contact-form .input input {
  width: 100%;
  height: 65px;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 0px 20px;
  border-radius: 5px;
}
.contact-form .input input:focus {
  border: 1px solid var(--tp-common-green);
}
.contact-form .textarea {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .contact-form .textarea {
    margin-bottom: 10px;
  }
}
.contact-form .textarea textarea {
  width: 100%;
  height: 180px;
  padding: 15px 20px;
  border: 1px solid transparent;
  outline: 0;
  resize: none;
  border-radius: 5px;
  transition: 0.3s;
}
.contact-form .textarea textarea:focus {
  border: 1px solid var(--tp-common-green);
}

.am-contact-info {
  margin-bottom: 35px;
}

/*----------------------------------------*/
/*  06. service CSS START
/*----------------------------------------*/
.tp-service-sm-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-service-sm-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .tp-service-sm-title {
    font-size: 15px;
    line-height: 26px;
  }
  .tp-service-sm-title br {
    display: none;
  }
}

.service-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.service-space-left {
  padding-left: 45px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .service-space-left {
    padding-left: 0;
  }
}

/* .service-clickable-area{
  display: block;
} */
.service-clickable-area{
  position: relative;
}

.service-clickable-link{
  display: block;
  position: absolute;
  z-index:1;
}

.tp-service-item {
  background-color: var(--tp-common-yellow-2);
  padding: 60px;
  border-radius: 20px;
}
@media (max-width: 767px) {
  .tp-service-item {
    padding: 17px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-service-item {
    padding: 30px;
  }
}
.tp-service-item__content .tp-service-sm-title {
  margin-bottom: 35px;
}
.tp-service-item__button-link a {
  padding: 15px 20px 15px 5px;
  background-color: var(--tp-common-white);
  border-radius: 100px;
  display: inline-block;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--tp-common-black);
  line-height: 1;
}
.tp-service-item__button-link a:hover {
  color: var(--tp-common-yellow);
}
@media (max-width: 767px) {
  .tp-service-item__button-link a {
    font-size: 12px;
    padding: 10px 5px 10px 0px;
  }
}
.tp-service-item__button-link a span {
  position: relative;
  margin-right: 10px;
}
.tp-service-item__button-link a span::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 13px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--tp-common-yellow);
}
@media (max-width: 767px) {
  .tp-service-item__button-link a span::after {
    top: -2px;
    left: 17px;
    height: 20px;
    width: 20px;
  }
}
.tp-service-item__button-link a span svg {
  color: var(--tp-common-white);
  position: relative;
  z-index: 5;
}
.tp-service-item__icon span {
  height: 67px;
  width: 67px;
  background-color: var(--tp-common-yellow);
  display: inline-block;
  text-align: center;
  line-height: 67px;
  border-radius: 50%;
}
.tp-service-item__icon span svg {
  color: var(--tp-common-white);
}
.tp-service-item.sv-color-blue-one {
  background-color: var(--tp-common-green-2);
}
.tp-service-item.sv-color-blue-one .tp-service-item__button-link a:hover {
  color: var(--tp-common-green);
}
.tp-service-item.sv-color-blue-one .tp-service-item__button-link a span::after {
  background-color: var(--tp-common-green);
}
.tp-service-item.sv-color-blue-one .tp-service-item__icon span {
  background-color: var(--tp-common-green);
}
.tp-service-item.sv-color-blue-two {
  background-color: var(--tp-common-blue);
}
.tp-service-item.sv-color-blue-two .tp-service-item__button-link a:hover {
  color: var(--tp-theme-1);
}
.tp-service-item.sv-color-blue-two .tp-service-item__button-link a span::after {
  background-color: var(--tp-theme-1);
}
.tp-service-item.sv-color-blue-two .tp-service-item__icon span {
  background-color: var(--tp-theme-1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-service-content {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-space-bottom {
    padding-bottom: 0;
  }
}

.tp-service-item-two {
  background-color: var(--tp-common-black-3);
  padding: 80px 55px;
  border-radius: 20px;
  position: relative;
  transition: 0.3s;
}
.tp-service-item-two:hover {
  margin-top: -10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-service-item-two {
    padding: 55px 40px;
  }
}
.tp-service-item-two__icon {
  margin-bottom: 55px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-service-item-two__icon {
    margin-bottom: 30px;
  }
}
.tp-service-item-two__icon svg {
  min-height: 74px;
  min-width: 74px;
}
.tp-service-item-two__title {
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--tp-border-5);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-service-item-two__title {
    padding-bottom: 20px;
  }
}
.tp-service-item-two__title a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s linear;
}
.tp-service-item-two__title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.tp-service-item-two__button:hover span {
  color: var(--tp-common-green);
}
.tp-service-item-two__button span {
  font-weight: 400;
  font-size: 16px;
  color: var(--tp-grey-3);
  transition: 0.3s;
}
.tp-service-item-two__count {
  position: absolute;
  top: 80px;
  right: 55px;
}
.tp-service-item-two__count .counter-content {
  height: 50px;
  width: 50px;
  background-color: var(--tp-common-black);
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  border: 2px dashed var(--tp-border-5);
  position: relative;
}
.tp-service-item-two__count .counter-content::after {
  font-size: 14px;
  content: "0" counter(count);
  counter-increment: count;
  color: #fff;
  font-weight: 400;
}

.counter-row {
  counter-reset: count;
}

.service-item-three {
  padding: 55px 35px;
  border: 1px solid var(--tp-border-1);
  border-radius: 20px;
  transition: 0.4s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-item-three {
    padding: 40px 30px;
  }
}
@media (max-width: 767px) {
  .service-item-three {
    padding: 30px 30px;
  }
}
.service-item-three:hover {
  box-shadow: 0px 55px 89px rgba(0, 0, 0, 0.1);
}
.service-item-three__img {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .service-item-three__img {
    margin-bottom: 15px;
  }
}
.service-item-three__content {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .service-item-three__content {
    margin-bottom: 15px;
  }
}
.service-item-three__content p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #6A6A74;
  min-height: 150px;
}
@media (max-width: 767px) {
  .service-item-three__content p {
    font-size: 15px;
    margin-bottom: 0;
  }
}
.service-item-three__content .tp-service-sm-title {
  margin-bottom: 30px;
}
.service-item-three__content .tp-service-sm-title:hover {
  color: var(--tp-common-green);
}
@media (max-width: 767px) {
  .service-item-three__content .tp-service-sm-title {
    margin-bottom: 5px;
  }
}
.service-item-three__button a {
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--tp-common-black);
  line-height: 0;
  transition: 0.3s;
}
.service-item-three__button a:hover {
  color: var(--tp-common-green);
}
.service-item-three__button a:hover span::after {
  background-color: var(--tp-common-green);
}
.service-item-three__button a:hover span svg {
  color: var(--tp-common-white);
  position: relative;
  z-index: 5;
}
@media (max-width: 767px) {
  .service-item-three__button a {
    font-size: 13px;
  }
}
.service-item-three__button a span {
  position: relative;
  margin-right: 10px;
}
.service-item-three__button a span::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 13px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--tp-common-yellow);
  transition: 0.3s;
}
.service-item-three__button a span svg {
  color: var(--tp-common-white);
  position: relative;
  z-index: 5;
}

.sv-details-thumb img {
  border-radius: 15px;
}

.sv-details-title {
  font-weight: 600;
  font-size: 50px;
  letter-spacing: -0.01em;
  line-height: 1.1;
  text-transform: capitalize;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sv-details-title {
    font-size: 38px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .sv-details-title {
    font-size: 33px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sv-details-title {
    font-size: 40px;
    margin-bottom: 30px;
  }
}

.sv-details-title-sm {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .sv-details-content.sv-details-md-space {
    margin-bottom: 70px;
  }
}
.sv-details-content p {
  font-family: "Arimo";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #6A6A74;
}

/*----------------------------------------*/
/*  07. case CSS START
/*----------------------------------------*/
.case-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.case-space {
  padding-top: 130px;
  padding-bottom: 110px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case-space {
    padding-bottom: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 767px) {
  .case-space {
    padding-top: 0px;
  }
}

.tp-case-thumb {
  border-radius: 20px;
  border: 5px solid var(--tp-common-white);
}
.tp-case-thumb:hover img {
  transform: scale(1.1);
}
.tp-case-thumb img {
  border-radius: 15px;
  width: 100%;
  transition: 0.7s;
}

.tp-case-title {
  font-weight: 500;
  font-size: 32px;
  letter-spacing: -0.01em;
  color: var(--tp-common-white);
  padding-bottom: 15px;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .tp-case-title {
    font-size: 21px;
  }
}
.tp-case-title a:hover {
  color: var(--tp-common-green);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-case-title {
    font-size: 25px;
    padding-bottom: 5px;
  }
}

.tp-case-item__content {
  margin-top: 50px;
}
.tp-case-item:hover .tp-case-item__thumb {
  border-radius: 20px;
}
.tp-case-item:hover .tp-case-item__thumb img {
  transform: scale(1.1);
  border-radius: 20px;
}
.tp-case-item__thumb {
  border-radius: 20px;
}
.tp-case-item__thumb img {
  width: 100%;
  border-radius: 20px;
  transition: 0.9s;
}

.tp-case-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #DDDDDD;
}
.tp-case-subtitle::before {
  height: 15px;
  width: 15px;
  background-color: var(--tp-common-green);
  border-radius: 50%;
  content: "";
  display: inline-block;
  transform: translateY(2px);
  margin-right: 5px;
}

.tp-case-slider-active .slick-slide {
  padding: 0px 15px;
}

.tp-case-wrapper {
  margin-left: -300px;
  margin-right: -300px;
}
@media (max-width: 767px) {
  .tp-case-wrapper {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.tp-case-active .slick-slide {
  padding: 0px 15px;
}

.case-grident-bg {
  background: linear-gradient(180deg, #F7F7F7 26.29%, rgba(247, 247, 247, 0) 79.02%);
}

.tp-circle-text a {
  font-weight: 500;
  font-size: 15px;
  color: var(--tp-common-white);
  line-height: 1.3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  height: 110px;
  width: 110px;
  background-color: var(--tp-common-black);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}

.tp-case-active .tp-case-item {
  border: 5px solid var(--tp-common-white);
  border-radius: 20px;
}
.tp-case-active .tp-case-item__thumb img {
  margin-bottom: 0;
}

.tp-case-item {
  margin-bottom: 30px;
}
.tp-case-item:hover .tp-circle-text a {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.case-icon a {
  font-size: 20px;
  color: var(--tp-common-white);
  display: block;
  background-color: var(--tp-common-red);
  text-align: center;
  padding: 10px 0;
  margin-bottom: 15px;
  border-radius: 3px;
}
.case-icon a.pinterest {
  background-color: #E40028;
}
.case-icon a.twitter {
  background-color: #1DA1F2;
}
.case-icon a.behance {
  background-color: #0E63FF;
}

.case-details-title {
  font-weight: 600;
  font-size: 45px;
  line-height: 1.1;
  letter-spacing: -0.01em;
  text-transform: capitalize;
}

.case-custom-space-1 {
  padding-top: 80px;
}

.case-custom-space-2 {
  padding-right: 60px;
  border-right: 1px solid var(--tp-border-7);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .case-custom-space-2 {
    padding-right: 0px;
    border-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .case-custom-space-2 {
    padding-right: 0px;
    border-right: 0;
  }
}

.case-custom-space-3 {
  padding-left: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .case-custom-space-3 {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .case-custom-space-3 {
    padding-left: 0;
  }
}

.case-sapce-top {
  padding-top: 80px;
}

.case-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.case-icon a {
  height: 80px;
  width: 80px;
  background-color: var(--tp-common-green);
  color: var(--tp-common-white);
  font-size: 28px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  transform: rotate(30deg) scale(0.5);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.case-icon a:hover {
  color: var(--tp-common-white);
}

.tp-case-thumb:hover .case-icon a {
  transform: rotate(30deg) scale(1);
  opacity: 1;
  visibility: visible;
}

.case-social-icon {
  margin-right: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .case-social-icon {
    margin-right: 0;
  }
}
.case-social-icon a {
  display: block;
  padding: 10px 20px;
  background-color: var(--tp-common-green);
  color: var(--tp-common-white);
  text-align: center;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 20px;
}
.case-social-icon a.behance {
  background-color: #0E63FF;
}
.case-social-icon a.twitter {
  background-color: #1DA1F2;
}
.case-social-icon a.pinterest {
  background-color: #E40028;
}

/*----------------------------------------*/
/*  08. testimonial CSS START
/*----------------------------------------*/
.tp-testimonial-space {
  padding-top: 130px;
  padding-bottom: 110px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 767px) {
  .tp-testimonial-space {
    padding-top: 0;
  }
}

.tp-testimonial-item {
  background-color: var(--tp-common-yellow-2);
  padding: 25px;
  /* padding-right: 75px; */
  border-radius: 20px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-testimonial-item {
    padding: 50px 30px;
    padding-right: 30px;
    padding-right: 25px;
  }
}
@media (max-width: 767px) {
  .tp-testimonial-item {
    padding-right: 0px;
    padding: 20px 20px;
  }
}
.tp-testimonial-item__icon {
  margin-bottom: 20px;
}
.tp-testimonial-item__icon i {
  box-shadow: 0px 4px 4px rgba(238, 208, 100, 0.26);
  height: 56px;
  width: 56px;
  background-color: var(--tp-common-yellow);
  border-radius: 50%;
  text-align: center;
  line-height: 56px;
  font-weight: 900;
  font-size: 22px;
  color: var(--tp-common-white);
}
.tp-testimonial-item__text p {
  font-weight: 500;
  font-size: 20px;
  /* line-height: 40px; */
  color: var(--tp-common-black);
  padding-bottom: 20px;
  min-height: 308px;
}
@media (max-width: 767px) {
  .tp-testimonial-item__text p {
    font-size: 19px;
    line-height: 29px;
  }
}
.tp-testimonial-item__info-img {
  border: 4px solid var(--tp-common-white);
  border-radius: 50%;
  margin-right: 15px;
  flex: 0 0 auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-testimonial-item__info-img {
    margin-right: 10px;
  }
}
.tp-testimonial-item__info-text span {
  font-weight: 400;
  font-size: 14px;
  color: var(--tp-common-black);
}
.tp-testimonial-item__ratting span {
  color: var(--tp-common-yellow);
  margin-left: 3px;
}

.client-name {
  font-weight: 600;
  font-size: 20px;
}
@media (max-width: 767px) {
  .client-name {
    font-size: 16px;
  }
}

.testimonial-bg-color-2 {
  background-color: var(--tp-common-green-2);
}
.testimonial-bg-color-2 .tp-testimonial-item__icon i {
  background-color: var(--tp-common-green);
}
.testimonial-bg-color-2 .tp-testimonial-item__ratting span {
  color: var(--tp-common-green);
}

.testimonial-bg-color-3 {
  background-color: var(--tp-common-blue);
}
.testimonial-bg-color-3 .tp-testimonial-item__icon i {
  background-color: var(--tp-theme-1);
}
.testimonial-bg-color-3 .tp-testimonial-item__ratting span {
  color: var(--tp-theme-1);
}

.testimonial-border-shape {
  background-position: left top;
  background-repeat: no-repeat;
}

.tp-test-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-test-title {
    font-size: 17px;
  }
}

.tp-testi-2 {
  background-color: var(--tp-common-black-3);
  padding: 80px 120px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-testi-2 {
    padding: 55px 55px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-testi-2 {
    padding: 50px 70px;
  }
}
@media (max-width: 767px) {
  .tp-testi-2 {
    padding: 20px 20px;
  }
}
.tp-testi-2__wrapper {
  margin-bottom: 45px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-testi-2__wrapper {
    margin-bottom: 20px;
  }
}
.tp-testi-2__wrapper-icon i {
  color: #1C9CEA;
  font-size: 40px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .tp-testi-2__wrapper-icon i {
    display: none;
  }
}
.tp-testi-2__meta-img {
  flex: 0 0 auto;
  margin-right: 30px;
}
.tp-testi-2__meta-img img {
  border: 5px solid var(--tp-common-white);
  border-radius: 50%;
}
.tp-testi-2__meta-info span {
  font-weight: 400;
  font-size: 14px;
  color: var(--tp-common-white);
}
.tp-testi-2__text p {
  font-weight: 400;
  font-size: 40px;
  color: #DDDDDD;
  line-height: 1.3;
  font-family: "Arimo";
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-testi-2__text p {
    font-size: 31px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-testi-2__text p {
    font-size: 27px;
  }
}
@media (max-width: 767px) {
  .tp-testi-2__text p {
    font-size: 21px;
  }
}

.tp-testi-bottom {
  padding: 40px 80px;
  padding-right: 60px;
  width: 33%;
  transition: 0.4s;
  border-right: 1px solid var(--tp-border-5);
  cursor: pointer;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-testi-bottom {
    padding: 30px 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-testi-bottom {
    padding: 40px 44px;
  }
}
@media (max-width: 767px) {
  .tp-testi-bottom {
    padding: 20px 20px;
    padding-right: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-testi-bottom {
    padding: 20px 60px;
  }
}

.tp-testi-sm-img img {
  margin-right: 15px;
  border: 5px solid var(--tp-common-white);
  border-radius: 50%;
}

.tp-test-border-wrapper {
  border: 1px solid var(--tp-border-5);
  border-top: 0;
}

.test-img-active {
  position: relative;
}
.test-img-active button {
  height: 40px;
  width: 40px;
  border: 1px dashed;
  display: inline-block;
  position: absolute;
  font-size: 16px;
  border-radius: 50%;
  background-color: var(--tp-common-black);
  z-index: 9;
  transition: 0.3s;
}
.test-img-active button:hover {
  background-color: var(--tp-common-green);
  color: var(--tp-common-white);
  border-color: var(--tp-common-green);
}
.test-img-active button.slick-prev {
  position: absolute;
  top: 50px;
  left: -20px;
}
.test-img-active button.slick-next {
  position: absolute;
  top: 50px;
  right: -20px;
}

.tp-testi-info-text.tp-test-title {
  margin-bottom: 10px;
}
.tp-testi-info-text span {
  font-weight: 400;
  font-size: 14px;
  color: var(--tp-common-white);
}

.tp-testimonial-three__content {
  background-color: var(--tp-common-white);
  border-radius: 15px;
  padding: 20px 50px;
  padding-top: 50px;
  min-height: 430px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-testimonial-three__content {
    padding: 20px 45px;
    min-height: 370px;
  }
}
@media (max-width: 767px) {
  .tp-testimonial-three__content {
    padding: 20px 22px;
    min-height: 310px;
  }
}
.tp-testimonial-three__content-icon {
  margin-bottom: 50px;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  line-height: 45px;
  text-align: center;
  color: var(--tp-common-white);
  font-weight: 500;
  background-color: var(--tp-common-red);
  display: inline-block;
}
@media (max-width: 767px) {
  .tp-testimonial-three__content-icon {
    margin-bottom: 20px;
  }
}
.tp-testimonial-three__content-icon.content-icon-1 {
  background-color: var(--tp-common-yellow);
}
.tp-testimonial-three__content-icon.content-icon-2 {
  background-color: var(--tp-common-green);
}
.tp-testimonial-three__content-icon.content-icon-3 {
  background-color: var(--tp-theme-1);
}
.tp-testimonial-three__content-icon.content-icon-4 {
  background-color: var(--tp-common-sky);
}
.tp-testimonial-three__content-icon.content-icon-5 {
  background-color: var(--tp-common-orange);
}
.tp-testimonial-three__content-text p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 0;
}
.tp-testimonial-three__user {
  margin-top: -70px;
  margin-bottom:30px;
}
.tp-testimonial-three__user-img {
  margin-bottom: 25px;
}
.tp-testimonial-three__user-content span {
  font-weight: 400;
  font-size: 14px;
}

.user-title {
  font-weight: 600;
  font-size: 20px;
  color: var(--tp-common-black);
}

.testimonial-slide-wrapper {
  margin-left: -140px;
  margin-right: -140px;
}
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .testimonial-slide-wrapper {
    margin-left: -120px;
    margin-right: -120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .testimonial-slide-wrapper {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.testimonial-slide-wrapper .slick-slide {
  padding: 0px 15px;
}
.testimonial-slide-wrapper .slick-slide img {
  display: inline-block;
}

.test-img-active .slick-current .tp-testi-bottom {
  border-bottom: 2px solid var(--tp-common-green);
}

/*----------------------------------------*/
/*  09. barnd CSS START
/*----------------------------------------*/
.tp-brand-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4;
}

.tp-brand-title-box span {
  color: var(--tp-theme-1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-space-bottom {
    padding-bottom: 0px;
  }
}

.tp-brand-item-two {
  height: 278px;
  width: 278px;
  border: 2px dashed var(--tp-border-5);
  border-radius: 50%;
  text-align: center;
  line-height: 278px;
  margin: 0 -10px 0px -10px;
  transition: 0.3s;
}
.tp-brand-item-two:hover {
  margin-top: -10px;
  border-color: var(--tp-common-green);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-brand-item-two {
    height: 240px;
    width: 240px;
    line-height: 240px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-brand-item-two {
    height: 200px;
    width: 200px;
    line-height: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-brand-item-two {
    height: 250px;
    width: 250px;
    line-height: 250px;
  }
}
@media (max-width: 767px) {
  .tp-brand-item-two {
    margin: 0px 0px 0px 0px;
    margin: 0 auto;
  }
}

.brand-center .brand-logo {
  text-align: center;
}

@media (max-width: 767px) {
  .brand-logo {
    text-align: center;
  }
}

/*----------------------------------------*/
/*  10. blog CSS START
/*----------------------------------------*/
.blog-grident-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-space {
  padding-top: 90px;
  padding-bottom: 90px;
}
@media (max-width: 767px) {
  .blog-space {
    padding-top: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-space {
    padding-top: 0;
  }
}

.tp-blog-item {
  background-color: var(--tp-common-white);
  padding: 30px;
  border-radius: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-blog-item {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .tp-blog-item {
    padding: 20px;
  }
}
.tp-blog-item:hover .tp-blog-item__thumb {
  border-radius: 20px;
}
.tp-blog-item:hover .tp-blog-item__thumb img {
  border-radius: 20px;
  transform: scale(1.1);
}
.tp-blog-item__thumb {
  margin-bottom: 40px;
  border-radius: 20px;
}
.tp-blog-item__thumb img {
  border-radius: 20px;
  transform: scale(1);
  transition: 0.4s;
}
.tp-blog-item__tag {
  margin-bottom: 30px;
}
.tp-blog-item__tag span {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--tp-common-white);
  background-color: var(--tp-theme-1);
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  display: inline-block;
}
@media (max-width: 767px) {
  .tp-blog-item__tag span {
    font-size: 12px;
    padding: 0px 10px;
  }
}
.tp-blog-item__tag span.tag-color-green {
  background-color: var(--tp-common-green);
}
.tp-blog-item__tag span:first-child {
  margin-right: 10px;
}
@media (max-width: 767px) {
  .tp-blog-item__tag span:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.tp-blog-item__blog-title {
  margin-bottom: 25px;
}
.tp-blog-item__button {
  padding-bottom: 15px;
}

.tp-blog-title-lg {
  font-weight: 500;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: -0.01em;
}
.tp-blog-title-lg a:hover {
  color: var(--tp-theme-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-blog-title-lg {
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .tp-blog-title-lg {
    font-size: 20px;
    line-height: 28px;
  }
}

.tp-blog-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.01em;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-blog-title {
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .tp-blog-title {
    font-size: 20px;
    line-height: 28px;
  }
}

.blog-item-two {
  background-color: var(--tp-common-black-3);
  border-radius: 15px 15px 0 0;
}
.blog-item-two:hover .blog-item-two__thumb {
  border-radius: 15px 15px 0 0;
}
.blog-item-two:hover .blog-item-two__thumb img {
  border-radius: 15px 15px 0 0;
  transform: scale(1.1);
}
.blog-item-two__thumb {
  border-radius: 15px 15px 0 0;
}
.blog-item-two__thumb img {
  border-radius: 15px 15px 0 0;
  width: 100%;
  transform: scale(1);
}
.blog-item-two__content {
  padding: 35px 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-item-two__content {
    padding: 30px 20px;
  }
}
.blog-item-two__content-meta {
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: var(--tp-grey-5);
  padding-bottom: 15px;
}
.blog-item-two__content-title {
  padding-bottom: 15px;
}
.blog-item-two__content-title a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s linear;
}
.blog-item-two__content-title a:hover {
  background-size: 0% 1px, 100% 1px;
}

.tp-blog-wrapper {
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .tp-blog-wrapper .tp-blog-button {
    margin-top: 30px;
  }
}

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .postbox__wrapper {
    padding-right: 0px;
    margin-bottom: 50px;
  }
}

.postbox__thumb .play-btn {
  position: absolute;
  top: calc(50% - 20px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 90px;
  width: 90px;
  background-color: var(--tp-common-white);
  text-align: center;
  line-height: 90px;
  color: var(--tp-theme-2);
  border-radius: 50%;
  animation: pulse 2s infinite;
}
.postbox__thumb .play-btn:hover {
  background-color: var(--tp-theme-1);
  color: var(--tp-common-white);
}
.postbox__thumb img {
  border-radius: 20px;
  margin-bottom: 40px;
}
.postbox__audio {
  height: 100%;
  width: 100%;
}
.postbox__audio iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.postbox__item-single:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.postbox__content p img {
  margin-bottom: 30px;
  max-width: 100%;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .postbox__content p img {
    width: 100%;
  }
}
.postbox__content-single {
  padding-left: 0;
  padding-right: 0;
  border: none;
}
.postbox__title {
  font-size: 36px;
  font-weight: 600;
  color: var(--tp-common-black);
  line-height: 1.2;
  margin-bottom: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .postbox__title {
    font-size: 33px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__title {
    font-size: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .postbox__title {
    font-size: 33px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .postbox__title {
    font-size: 30px;
  }
  .postbox__title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .postbox__title {
    font-size: 25px;
  }
  .postbox__title br {
    display: none;
  }
}
.postbox__title a:hover {
  color: var(--tp-theme-1);
}
.postbox__meta {
  margin-bottom: 20px;
}
.postbox__meta span {
  font-size: 14px;
  font-weight: 700;
  color: #8A90A2;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 30px;
}
.postbox__meta span:last-child {
  margin-right: 0;
}
.postbox__meta span i {
  color: var(--tp-theme-1);
  margin-right: 3px;
  font-weight: 500;
}
.postbox__meta span:hover {
  color: var(--tp-common-black);
}
.postbox__text {
  margin-bottom: 50px;
  padding-right: 30px;
}
.postbox__text img {
  max-width: 100%;
}
.postbox__text p {
  margin-bottom: 28px;
  font-size: 17px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .postbox__text p {
    font-size: 16px;
  }
}
.postbox__text-single p {
  margin-bottom: 15px;
}
.postbox__slider button {
  position: absolute;
  left: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 30px;
  color: var(--tp-common-white);
}
.postbox__slider button.slick-next {
  left: auto;
  right: 50px;
}
@media (max-width: 767px) {
  .postbox__slider button.slick-next {
    right: 10px;
  }
}
@media (max-width: 767px) {
  .postbox__slider button {
    left: 10px;
  }
}
.postbox__comment ul li {
  margin-bottom: 30px;
  list-style: none;
}
.postbox__comment ul li.children {
  margin-left: 100px;
}
@media (max-width: 767px) {
  .postbox__comment ul li.children {
    margin-left: 15px;
  }
}
.postbox__comment ul-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--tp-common-black);
  margin-bottom: 40px;
  margin-right: 20px;
}
.postbox__comment-input {
  position: relative;
  margin-bottom: 20px;
}
.postbox__comment-input span {
  font-weight: 600;
  color: var(--tp-common-black);
  margin-bottom: 12px;
  display: block;
}
.postbox__comment-input input, .postbox__comment-input textarea {
  height: 55px;
  padding: 0 20px;
  width: 100%;
  font-size: 14px;
  outline: none;
  color: var(--tp-common-black);
  background-color: var(--tp-grey-6);
  border: 1px solid #f7f7f7;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.postbox__comment-input input:focus, .postbox__comment-input textarea:focus {
  border-color: var(--tp-common-green);
}
.postbox__comment-input textarea {
  height: 175px;
  resize: none;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--tp-common-black);
  background-color: var(--tp-grey-6);
}
.postbox__comment-title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 35px;
  color: var(--tp-common-black);
}
.postbox__comment-avater img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.postbox__comment-name {
  margin-bottom: 5px;
}
.postbox__comment-name h5 {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: var(--tp-common-black);
}
.postbox__comment-name span {
  font-size: 14px;
  color: #8A879F;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 767px) {
  .postbox__comment-text {
    margin-left: 0;
    margin-top: 15px;
  }
}
.postbox__comment-text p {
  font-size: 16px;
  color: var(--tp-text-11);
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .postbox__comment-text p br {
    display: none;
  }
}
.postbox__comment-reply {
  margin-top: 10px;
}
.postbox__comment-reply a {
  display: inline-block;
  color: var(--tp-theme-1);
  background: rgba(61, 108, 231, 0.1);
  height: 22px;
  line-height: 22px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.postbox__comment-reply a:hover {
  color: var(--tp-common-white);
  background: var(--tp-theme-1);
}
.postbox__comment-agree {
  padding-left: 5px;
}
.postbox__comment-agree input {
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  display: block;
  width: 14px;
  height: 14px;
  background: var(--tp-common-white);
  border: 1px solid #b9bac1;
  outline: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  flex: 0 0 auto;
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
}
.postbox__comment-agree input:checked {
  position: relative;
  background-color: var(--tp-theme-1);
  border-color: transparent;
}
.postbox__comment-agree input:checked::after {
  box-sizing: border-box;
  content: "\f00c";
  position: absolute;
  font-family: var(--tp-ff-fontawesome);
  font-size: 10px;
  color: var(--tp-common-white);
  top: 46%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.postbox__comment-agree input:hover {
  cursor: pointer;
}
.postbox__comment-agree label {
  padding-left: 8px;
  color: var(--tp-text-1);
  line-height: 1;
}
.postbox__comment-agree label a {
  color: var(--tp-common-black);
  font-weight: 600;
  padding-left: 4px;
}
.postbox__comment-agree label a:hover {
  color: var(--tp-theme-1);
}
.postbox__comment-agree label:hover {
  cursor: pointer;
}
.postbox__tag span {
  font-size: 20px;
  margin-bottom: 17px;
  color: var(--tp-common-black);
  margin-right: 30px;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .postbox__social {
    margin-top: 40px;
  }
}
.postbox__social span {
  font-size: 20px;
  color: var(--tp-common-black);
  font-weight: 500;
}
.postbox__social a {
  font-size: 15px;
  padding-left: 30px;
}
.postbox__social a .tp-linkedin {
  color: #0E6BA1;
}
.postbox__social a .tp-pinterest {
  color: #D70220;
}
.postbox__social a .tp-facebook {
  color: #0E6BA1;
}
.postbox__social a .tp-twitter {
  color: #36B6ED;
}

.postbox__comment-form-title {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 35px;
  color: var(--tp-common-black);
}
@media (max-width: 767px) {
  .postbox__comment-form-title {
    font-size: 25px;
  }
}

.postbox__social-wrapper {
  border-bottom: 1px solid #ECEEF3;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .postbox__comment-form {
    margin-bottom: 50px;
  }
}

.aproch-title {
  font-size: 36px;
  margin-bottom: 15px;
}

.approch-item {
  margin-bottom: 65px;
}
.approch-item ul li {
  position: relative;
  padding-left: 30px;
}
.approch-item ul li i {
  color: var(--tp-theme-2);
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 5px;
  left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .approch-thumb {
    margin-bottom: 40px;
  }
}
.approch-thumb img {
  border-radius: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .approch-thumb img {
    width: 100%;
  }
}

.postbox__comment-info {
  flex: 0 0 auto;
}

.rc__post ul li:not(:last-child) {
  margin-bottom: 15px;
}
.rc__post-thumb {
  flex: 0 0 auto;
}
.rc__post-thumb img {
  border-radius: 10px;
  width: 100px;
  height: 80px;
  flex: 0 0 auto;
  object-fit: cover;
}
.rc__post-title {
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: 500;
  color: var(--tp-common-black);
  line-height: 22px;
}
.rc__post-title a:hover {
  color: var(--tp-theme-1);
}
.rc__meta span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--tp-theme-3);
}

.sidebar__wrapper {
  padding-left: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .sidebar__wrapper {
    padding-left: 0;
  }
}
.sidebar__widget {
  padding: 40px;
  border: 1px solid #F6F6F6;
}
@media (max-width: 767px) {
  .sidebar__widget {
    padding-left: 20px;
  }
}
.sidebar__widget-title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
  color: var(--tp-common-black);
}
.sidebar__widget:last-child-title {
  border-bottom: 0;
}
.sidebar__widget ul li {
  list-style: none;
}
.sidebar__widget ul li:last-child {
  padding-bottom: 0;
}
.sidebar__widget ul li:last-child a {
  margin-bottom: 0;
}
.sidebar__widget ul li:first-child {
  padding-top: 0;
}
.sidebar__widget ul li a {
  color: #6A6A74;
  display: block;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 7px 0px;
}
.sidebar__widget ul li a:hover {
  color: var(--tp-common-black);
}
.sidebar__widget ul li a:hover span {
  color: var(--tp-common-black);
}
.sidebar__widget ul li span {
  float: right;
  color: #6A6A74;
  font-size: 15px;
  font-weight: 500;
}
.sidebar__search {
  position: relative;
}
.sidebar__search input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: var(--tp-grey-1);
  padding: 0 25px;
  text-transform: capitalize;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  border: 1px solid var(--tp-grey-1);
  outline: none;
  padding-top: 3px;
  padding-right: 80px;
}
@media (max-width: 767px) {
  .sidebar__search input {
    padding-right: 40px;
    padding-left: 15px;
  }
}
.sidebar__search input:focus {
  border-color: var(--tp-common-green);
}
.sidebar__search input::placeholder {
  color: #777777;
}
.sidebar__search button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 24px;
  color: #fff;
  line-height: 60px;
  -webkit-border-radius: 0 7px 7px 0;
  -moz-border-radius: 0 7px 7px 0;
  -o-border-radius: 0 7px 7px 0;
  -ms-border-radius: 0 7px 7px 0;
  border-radius: 0 7px 7px 0;
  color: var(--tp-common-black);
}
.sidebar__banner-content {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: var(--tp-theme-1);
}
.sidebar__banner-content h4 {
  padding: 15px 20px;
  font-size: 24px;
  color: var(--tp-common-white);
  text-transform: uppercase;
  margin-bottom: 0;
}

.tagcloud a {
  border: 1px solid #F2F4F6;
  color: var(--tp-theme-3);
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 10px 25px;
  margin-bottom: 8px;
  margin-right: 10px;
  text-transform: uppercase;
  border-radius: 5px;
  transition: 0.3s;
}
.tagcloud a:hover {
  background: var(--tp-theme-1);
  color: var(--tp-common-white);
  border-color: var(--tp-theme-1);
}

blockquote {
  background: var(--tp-grey-1);
  padding: 35px 50px;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  blockquote {
    padding-left: 15px;
    padding-right: 15px;
  }
}
blockquote p {
  line-height: 1.5;
  font-size: 20px;
  color: #57565e;
  font-weight: 400;
}
blockquote cite {
  font-size: 18px;
  display: block;
  margin-top: 10px;
  color: #070337;
  font-style: inherit;
  font-weight: 600;
  position: relative;
}
blockquote cite::before {
  content: "";
  font-size: 28px;
  color: var(--tp-theme-1);
  padding-bottom: 0px;
  display: inline-block;
  background: var(--tp-theme-1);
  height: 2px;
  width: 40px;
  font-weight: 400;
  text-align: center;
  top: -4px;
  margin-right: 10px;
  position: relative;
}

.blog-post-slider-active {
  position: relative;
  margin-bottom: 40px;
}
.blog-post-slider-active .swiper-slide img {
  border-radius: 5px;
  width: 100%;
}

.blog-nav-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 5;
}

.blog-nav-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 5;
}

.blog-post-slider-nav .nav-button {
  height: 60px;
  width: 60px;
  background-color: var(--tp-common-white);
  text-align: center;
  line-height: 60px;
  font-size: 24px;
  color: var(--tp-common-black);
}

.basic-pagination ul li:not(:last-child) {
  margin-right: 10px;
}

.basic-pagination ul li {
  display: inline-block;
}

.basic-pagination ul li a, .basic-pagination ul li span {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 46px;
  text-align: center;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  border: 1px solid var(--tp-border-1);
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .basic-pagination ul li a, .basic-pagination ul li span {
    width: 40px;
    height: 40px;
    line-height: 38px;
  }
}

.basic-pagination ul li a:hover, .basic-pagination ul li a.current, .basic-pagination ul li span:hover, .basic-pagination ul li span.current {
  background: var(--tp-theme-1);
  border-color: var(--tp-theme-1);
  color: var(--tp-common-white);
}

/*----------------------------------------*/
/*  11. social CSS START
/*----------------------------------------*/
.tp-social-item {
  padding: 50px 20px;
  text-align: center;
  border: 1px solid var(--tp-border-1);
  margin: 0px -1px 0px -1px;
  border-bottom: 3px solid #4064AC;
  transition: 0.3s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-social-item {
    padding: 40px 15px;
  }
}
.tp-social-item:hover {
  background-color: #4064AC;
}
.tp-social-item:hover span {
  color: var(--tp-common-white);
}
.tp-social-item:hover span i {
  color: var(--tp-common-white);
}
.tp-social-item span {
  font-weight: 400;
  font-size: 22px;
  text-transform: uppercase;
  color: var(--tp-common-black);
  transition: 0.3s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-social-item span {
    font-size: 19px;
  }
}
.tp-social-item span i {
  color: #4064AC;
  margin-right: 10px;
  transition: 0.3s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-social-item span i {
    margin-right: 6px;
  }
}
.tp-social-item.tp-youtube {
  border-bottom: 3px solid #FF0000;
}
.tp-social-item.tp-youtube:hover {
  background-color: #FF0000;
}
.tp-social-item.tp-youtube:hover i {
  color: var(--tp-common-white);
}
.tp-social-item.tp-youtube span i {
  color: #FF0000;
}
.tp-social-item.tp-behance {
  border-bottom: 3px solid #1DA1F2;
}
.tp-social-item.tp-behance:hover {
  background-color: #1DA1F2;
}
.tp-social-item.tp-behance:hover i {
  color: var(--tp-common-white);
}
.tp-social-item.tp-behance span i {
  color: #1DA1F2;
}
.tp-social-item.tp-dribble {
  border-bottom: 3px solid #FF56BB;
}
.tp-social-item.tp-dribble:hover {
  background-color: #FF56BB;
}
.tp-social-item.tp-dribble:hover i {
  color: var(--tp-common-white);
}
.tp-social-item.tp-dribble span i {
  color: #FF56BB;
}
.tp-social-item.tp-twitter {
  border-bottom: 3px solid #1DA1F2;
}
.tp-social-item.tp-twitter:hover {
  background-color: #1DA1F2;
}
.tp-social-item.tp-twitter:hover i {
  color: var(--tp-common-white);
}
.tp-social-item.tp-twitter span i {
  color: #1DA1F2;
}
.tp-social-item.tp-linkedin {
  border-bottom: 3px solid #4064AC;
}
.tp-social-item.tp-linkedin:hover {
  background-color: #4064AC;
}
.tp-social-item.tp-linkedin:hover i {
  color: var(--tp-common-white);
}
.tp-social-item.tp-linkedin span i {
  color: #4064AC;
}

/*----------------------------------------*/
/*  12. cta CSS START
/*----------------------------------------*/
.tp-cta-item {
  margin: 0px -20px 0px -20px;
  border: 2px dashed var(--tp-common-green);
  border-radius: 50%;
  width: 580px;
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-cta-item {
    width: 500px;
    height: 500px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-cta-item {
    width: 370px;
    height: 370px;
    margin: 0px -20px 0px -20px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .tp-cta-item {
    width: 300px;
    height: 300px;
    margin: 0px 0px 0px 0px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-cta-item {
    width: 280px;
    height: 280px;
    margin: 0px 0px 0px 0px;
    margin: 0 auto;
  }
}
.tp-cta-item.orange-border {
  border-color: var(--tp-common-orange);
}

.tp-cta-title {
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -0.01em;
  color: var(--tp-common-white);
  margin-bottom: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-cta-title {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .tp-cta-title {
    font-size: 27px;
    margin-bottom: 20px;
  }
}

.cta-big-text {
  font-weight: 600;
  font-size: 122px;
  letter-spacing: -0.02em;
  margin-bottom: 0;
  -webkit-text-stroke: 1px #000;
  color: transparent;
  transition: 0.4s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-big-text {
    font-size: 95px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-big-text {
    font-size: 75px;
  }
}
@media (max-width: 767px) {
  .cta-big-text {
    font-size: 90px;
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cta-big-text {
    font-size: 55px;
  }
}

.cta-effect-img {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  z-index: -1;
}

.cta-space {
  padding-bottom: 140px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-space {
    padding-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .cta-space {
    padding-bottom: 40px;
  }
}

.cta-item:hover .cta-big-text {
  -webkit-text-stroke: 1px transparent;
  color: var(--tp-common-black);
}
.cta-item:hover .cta-effect-img {
  bottom: 50px;
  visibility: visible;
  opacity: 1;
}

.marque-item {
  padding-top: 40px;
}
.marque-item span {
  font-weight: 600;
  font-size: 122px;
  letter-spacing: -0.02em;
  -webkit-text-stroke: 1px #000;
  color: transparent;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .marque-item span {
    font-size: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .marque-item span {
    font-size: 75px;
  }
}
@media (max-width: 767px) {
  .marque-item span {
    font-size: 70px;
  }
}

.tp-marque-wrapper {
  margin-left: -200px;
  margin-right: -200px;
}

.marque-slider-active .marque-item {
  padding-bottom: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .marque-slider-active .marque-item {
    padding-bottom: 50px;
  }
}
.marque-slider-active .slick-slide {
  padding: 0px 55px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .marque-slider-active .slick-slide {
    padding: 0px 20px;
  }
}
@media (max-width: 767px) {
  .marque-slider-active .slick-slide {
    padding: 0px 10px;
  }
}

.marque-slider-active-2 .marque-item {
  padding-bottom: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .marque-slider-active-2 .marque-item {
    padding-bottom: 90px;
  }
}
.marque-slider-active-2 .slick-slide {
  padding: 0px 55px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .marque-slider-active-2 .slick-slide {
    padding: 0px 20px;
  }
}
@media (max-width: 767px) {
  .marque-slider-active-2 .slick-slide {
    padding: 0px 10px;
  }
}
.marque-slider-active-2 .marque-item {
  padding-top: 40px;
}
.marque-slider-active-2 .marque-item span {
  font-weight: 600;
  font-size: 122px;
  letter-spacing: -0.02em;
  color: #000;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .marque-slider-active-2 .marque-item span {
    font-size: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .marque-slider-active-2 .marque-item span {
    font-size: 75px;
  }
}
@media (max-width: 767px) {
  .marque-slider-active-2 .marque-item span {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-space-three {
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  .cta-space-three {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .cta-img {
    margin-top: 30px;
  }
}

/*----------------------------------------*/
/*  13. cart CSS START
/*----------------------------------------*/
/* 16. Cart */
.table-content .table > :not(:first-child) {
  border-top: 0;
}
.table-content table {
  background: #ffffff;
  border-color: #eaedff;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.table-content .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #eaedff;
}

.table-content .product-quantity {
  float: none;
}

.table-content table td.product-name {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.table-content table td.product-name a:hover {
  color: var(--tp-theme-1);
}

.table-content table td {
  border-top: medium none;
  padding: 20px 10px;
  vertical-align: middle;
  font-size: 16px;
}

.table-content table th, .table-content table td {
  border-bottom: 1px solid #eaedff;
  border-right: 1px solid #eaedff;
}

.product-quantity input {
  color: #000;
  font-size: 14px;
  font-weight: normal;
}

.table td, .table th {
  border-top: 1px solid #eaedff;
}

.product-quantity > input {
  width: 80px;
  text-align: center;
}

.table-content table td.product-subtotal {
  font-size: 16px;
}

.table-content table td .cart-plus-minus {
  float: none;
  margin: 0 auto;
}

.coupon-all {
  margin-top: 50px;
}

.coupon {
  float: left;
}

@media (max-width: 767px) {
  .coupon {
    float: none;
  }
}
#coupon_code {
  height: 70px;
  border: 2px solid #eaedff;
  padding: 0 15px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  #coupon_code {
    margin-bottom: 15px;
  }
}
.coupon2 {
  float: right;
}

.checkout-link .tp-btn:hover {
  background-color: var(--tp-theme-2);
}
.checkout-link .tp-btn-dark-lg:hover {
  background-color: var(--tp-theme-1);
}

@media (max-width: 767px) {
  .coupon2 {
    float: none;
    margin-top: 15px;
  }
}
.cart-page-total {
  padding-top: 50px;
}

.cart-page-total > h2 {
  font-size: 25px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #eaedff;
}

.cart-page-total > ul > li {
  list-style: none;
  font-size: 15px;
  color: #6f7172;
  padding: 20px 30px;
  border-bottom: 1px solid #eaedff;
  font-weight: 400;
}

.cart-page-total ul > li > span {
  float: right;
}

.cart-page-total li:last-child {
  border-bottom: 0;
}

td.product-thumbnail img {
  width: 125px;
}

/* 17. Checkout */
.coupon-accordion h3 {
  background-color: #f7f7f7;
  border-top: 3px solid var(--tp-theme-1);
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 25px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  width: auto;
}

.coupon-accordion h3::before {
  content: "\f07b";
  left: 15px;
  top: 13px;
  position: absolute;
  color: var(--tp-theme-1);
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
}

.coupon-accordion span {
  color: #6f7172;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
}

.coupon-accordion span:hover, p.lost-password a:hover {
  color: var(--tp-common-black);
}

.coupon-content {
  border: 1px solid #eaedff;
  display: none;
  margin-bottom: 20px;
  padding: 30px;
}

.coupon-info p.coupon-text {
  margin-bottom: 15px;
}

.coupon-info p {
  margin-bottom: 0;
}

.coupon-info p.form-row-first label, .coupon-info p.form-row-last label {
  display: block;
  color: #6f7172;
}

.coupon-info p.form-row-first label span.required, .coupon-info p.form-row-last label span.required {
  color: var(--tp-theme-1);
  font-weight: 700;
}

.coupon-info p.form-row-first input, .coupon-info p.form-row-last input {
  border: 1px solid #eaedff;
  height: 45px;
  margin: 0 0 14px;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  font-size: 14px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.coupon-info p.form-row-first input:focus, .coupon-info p.form-row-last input:focus {
  border-color: var(--tp-common-green);
}

.coupon-info p.form-row input[type=submit]:hover, p.checkout-coupon input[type=submit]:hover {
  background: #3e976c none repeat scroll 0 0;
}

.coupon-info p.form-row input[type=checkbox] {
  position: relative;
  top: -1px;
}

.form-row > label {
  margin-top: 15px;
  margin-left: 15px;
  color: #6f7172;
}

.buttons-cart input, .coupon input[type=submit], .buttons-cart a, .coupon-info p.form-row input[type=submit] {
  background: #252525 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

p.lost-password {
  margin-top: 15px;
}

p.lost-password a {
  color: #6f6f6f;
}

p.checkout-coupon input[type=text] {
  height: 50px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #eaedff;
  margin-bottom: 15px;
  font-size: 14px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
p.checkout-coupon input[type=text]:focus {
  border-color: var(--tp-common-green);
}

.coupon-checkout-content {
  display: none;
}

.checkbox-form h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 26px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.country-select {
  margin-bottom: 30px;
  position: relative;
}

.country-select select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #eaedff;
  padding: 0 10px;
  height: 50px;
}

.country-select label, .checkout-form-list label {
  color: #6f7172;
  display: block;
  margin: 0 0 5px;
}

.country-select label span.required, .checkout-form-list label span.required {
  color: var(--tp-theme-1);
}

.country-select .nice-select {
  border: 1px solid #eaedff;
  height: 45px;
  padding-left: 10px;
  width: 100%;
  color: #6f7172;
  margin-bottom: 20px;
}

.nice-select.open {
  border-color: var(-tp-common-green);
}

.country-select .nice-select .list {
  width: 100%;
}

.checkout-form-list {
  margin-bottom: 30px;
}

.checkout-form-list label {
  color: #6f7172;
}

.checkout-form-list input[type=text], .checkout-form-list input[type=password], .checkout-form-list input[type=email] {
  background: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  height: 45px;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.checkout-form-list input[type=text]:focus, .checkout-form-list input[type=password]:focus, .checkout-form-list input[type=email]:focus {
  border-color: var(--tp-common-green);
}

.checkout-form-list input[type=text]::-moz-placeholder,
.checkout-form-list input[type=password]::-moz-placeholder,
.checkout-form-list input[type=email]::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type=text]::placeholder,
.checkout-form-list input[type=password]::placeholder,
.checkout-form-list input[type=email]::placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type=checkbox] {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.create-acc label {
  color: #6f7172;
  display: inline-block;
}

.create-account {
  display: none;
}

.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  color: var(--tp-common-black);
}

.order-notes textarea {
  border: 1px solid #eaedff;
  height: 120px;
  padding: 15px;
  width: 100%;
  outline: 0;
  resize: none;
}

.order-notes textarea:focus {
  border-color: var(--tp-common-green);
}

.order-notes textarea::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.order-notes textarea::placeholder {
  color: #6f7172;
  opacity: 1;
}

#ship-box-info {
  display: none;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-default > .panel-heading {
  border-radius: 0;
}

.your-order {
  padding: 30px 40px 45px;
  border: 3px solid #f7f7f7;
}

@media (max-width: 767px) {
  .your-order {
    padding: 15px;
  }
}
.your-order h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 30px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.your-order-table table {
  background: none;
  border: 0;
  width: 100%;
}

.your-order-table table th, .your-order-table table td {
  border-bottom: 1px solid #eaedff;
  border-right: medium none;
  color: #6f7172;
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}

@media (max-width: 767px) {
  .your-order-table table th, .your-order-table table td {
    padding-right: 10px;
  }
}
.your-order-table table th {
  border-top: medium none;
  color: #6f7172;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.panel-body > p {
  color: #222;
}

.your-order-table table .shipping ul li {
  list-style: none;
}

.your-order-table table .shipping ul li input {
  position: relative;
  top: -1px;
}

.your-order-table table .shipping ul li label {
  color: #6f7172;
}

.your-order-table table .shipping th {
  vertical-align: top;
}

.your-order-table table .order-total th {
  border-bottom: 0;
  font-size: 14px;
}

.your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order-table table tr.cart_item:hover {
  background: #F9F9F9;
}

.your-order-table table tr.order-total td span {
  color: var(--tp-common-black);
  font-size: 18px;
  font-weight: 500;
}

.payment-method {
  margin-top: 40px;
}
.payment-method .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.payment-method .accordion-item {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
}
.payment-method .accordion-button {
  font-size: 16px;
  font-weight: 500;
  color: var(--tp-common-black);
  padding: 23px 0;
  border: none;
}
.payment-method .accordion-button:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.payment-method .accordion-button::after {
  position: absolute;
  content: "\f067";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 400;
  margin-left: 0;
  background-image: none;
  color: var(--tp-theme-1);
}
.payment-method .accordion-button:not(.collapsed) {
  color: var(--tp-common-black);
  background-color: var(--tp-common-white);
  box-shadow: none;
}
.payment-method .accordion-button:not(.collapsed)::after {
  content: "\f068";
}
.payment-method .accordion-body {
  padding: 8px 0;
  padding-bottom: 40px;
}
.payment-method .accordion-collapse {
  border: none;
}

.panel-title > a {
  display: block;
}

.order-button-payment input {
  background: #232323 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  margin: 0px 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.order-button-payment input:hover {
  background: #3e976c none repeat scroll 0 0;
}

.payment-method .btn-link {
  -moz-user-select: none;
  background: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.payment-method .card {
  background-color: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  margin-bottom: 10px;
}

.payment-method .accordion .card:first-of-type {
  border: 1px solid #eaedff;
}

.card-header:first-child {
  border-radius: 0;
}

.payment-method .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaedff;
}

.order-button-payment button {
  width: 100%;
}

.tp-shop-quantity {
  display: flex;
  display: inline-block;
}
.tp-shop-quantity .cart-minus {
  height: 38px;
  width: 38px;
  background-color: var(--tp-common-white);
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}
.tp-shop-quantity input {
  border: 0;
  background-color: transparent;
  height: 38px;
  width: 38px;
  text-align: center;
  line-height: 38px;
}
.tp-shop-quantity .cart-plus {
  height: 38px;
  width: 38px;
  background-color: var(--tp-common-white);
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}

.tp-quantity {
  display: flex;
  background-color: var(--tp-grey-1);
  padding: 3px;
}

.tp-shop-cart a {
  font-size: 16px;
  color: var(--tp-common-white);
  height: 42px;
  width: 42px;
  background-color: var(--tp-theme-2);
  display: inline-block;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
  transition: 0.3s;
}
.tp-shop-cart:hover a {
  background-color: var(--tp-theme-1);
}

.tp-shop-button {
  line-height: 1;
}

/*----------------------------------------*/
/*  14. team CSS START
/*----------------------------------------*/
.tp-team-thumb {
  height: 285px;
  width: 285px;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 50px;
  transition: 0.7s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-team-thumb {
    height: 240px;
    width: 240px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-team-thumb {
    height: 200px;
    width: 200px;
  }
}
@media (max-width: 767px) {
  .tp-team-thumb {
    height: 260px;
    width: 260px;
  }
}
.tp-team-thumb-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  z-index: 9;
}
.tp-team-thumb-icon a {
  height: 55px;
  width: 55px;
  background-color: var(--tp-common-white);
  border-radius: 50%;
  text-align: center;
  line-height: 65px;
  display: inline-block;
}
.tp-team-thumb-icon a i {
  transform: rotate(-38deg);
  font-size: 20px;
  font-weight: 600;
  color: var(--tp-common-black);
}
.tp-team-thumb:hover::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.tp-team-thumb:hover::after {
  border-color: var(--tp-common-green);
}
.tp-team-thumb:hover .tp-team-thumb-icon {
  opacity: 1;
  visibility: visible;
}
.tp-team-thumb::before {
  position: absolute;
  content: "";
  background-color: rgba(51, 204, 121, 0.7);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.7);
  transition: 0.4s;
}
.tp-team-thumb::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px dashed var(--tp-border-7);
  border-radius: 50%;
  transform: scale(1.1);
  transition: 0.4s;
}
.tp-team-thumb img {
  border-radius: 50%;
  width: 100%;
  transition: 0.7s;
}
.tp-team:hover-thumb img {
  transform: scale(1.1);
}

.tp-team-content span {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: var(--tp-grey-4);
  padding-bottom: 10px;
  display: inline-block;
}

.tp-team-social a {
  font-weight: 400;
  font-size: 14px;
  color: var(--tp-common-white);
  padding: 0px 20px;
  border-right: 1px solid var(--tp-border-5);
}
.tp-team-social a:hover {
  color: var(--tp-theme-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-team-social a {
    padding: 0px 15px;
  }
}
.tp-team-social a:last-child {
  border-right: 0;
}

.tp-team-title {
  font-size: 20px;
  font-weight: 600;
  transition: 0.3s;
}
.tp-team-title:hover a {
  color: var(--tp-common-green);
  display: inline-block;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-team-title {
    font-size: 18px;
  }
}

.team-inner .tp-team-content .tp-team-title {
  color: var(--tp-common-black);
}
.team-inner .tp-team-content span {
  color: #6A6A74;
}
.team-inner .tp-team-social a {
  color: var(--tp-common-black);
  border-right: 1px solid var(--tp-border-7);
}
.team-inner .tp-team-social a:hover {
  color: var(--tp-common-green);
}

/*----------------------------------------*/
/*  17. price CSS START
/*----------------------------------------*/
.tp-price {
  padding: 70px 60px;
  border-radius: 20px 0px 0px 20px;
  box-shadow: 0px 2px 12px rgba(20, 20, 43, 0.08);
  height: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-price {
    padding: 60px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-price {
    padding: 60px 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-price {
    padding: 60px 40px;
    border-radius: 20px 20px 0px 0px;
  }
}
@media (max-width: 767px) {
  .tp-price {
    padding: 20px 15px;
    border-radius: 20px 20px 0px 0px;
  }
}
.tp-price.price-grey-bg {
  background: #F7F7F7;
  border-radius: 0 20px 20px 0;
  border: 0;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tp-price.price-grey-bg {
    padding: 60px 40px;
    border-radius: 0px 0px 20px 20px;
  }
}
@media (max-width: 767px) {
  .tp-price.price-grey-bg {
    padding: 20px 15px;
    border-radius: 0px 0px 20px 20px;
  }
}
.tp-price__content {
  margin-bottom: 40px;
}
.tp-price__content-circle {
  height: 70px;
  width: 70px;
  background-color: #EDECFE;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.tp-price__content-circle span {
  height: 40px;
  width: 40px;
  background-color: var(--tp-common-white);
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(180deg);
}
.tp-price__content-circle span::before {
  content: "";
  height: 100%;
  width: 50%;
  background-color: var(--tp-common-deep-blue);
  display: inline-block;
}
.tp-price__content-text span {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #6A6A74;
}
.tp-price__content-text h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 0;
}
.tp-price p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}
.tp-price__price {
  margin-bottom: 30px;
  display: inline-block;
}
.tp-price__price span {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #6A6A74;
}
.tp-price__price b {
  font-weight: 600;
  font-size: 54px;
  line-height: 66px;
  color: var(--tp-common-black);
}
.tp-price__title {
  margin-bottom: 30px;
}
.tp-price__list ul li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-price__list ul li {
    margin-bottom: 10px;
  }
}
.tp-price__list ul li.price-disable {
  color: #BAB9CA;
}
.tp-price__list ul li.price-disable i {
  background-color: #BAB9CA;
}
.tp-price__list ul li i {
  height: 25px;
  width: 25px;
  background-color: var(--tp-common-deep-blue);
  color: var(--tp-common-white);
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  position: absolute;
  left: 0;
}

/*----------------------------------------*/
/*  18. product CSS START
/*----------------------------------------*/
.tp-product-title {
  font-size: 18px;
  color: var(--tp-common-black);
  font-weight: 500;
}
.tp-product-title:hover {
  color: var(--tp-theme-1);
}

.tpproduct__img {
  margin-bottom: 25px;
  position: relative;
}
.tpproduct__img::after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 41, 48, 0.6);
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.tpproduct__meta span {
  color: #777B83;
  font-size: 18px;
  font-weight: 500;
}
.tpproduct__meta .product-rating {
  color: #F7931E;
  margin-bottom: 5px;
}
.tpproduct:hover .tpproduct__img::after {
  opacity: 1;
  visibility: visible;
}
.tpproduct:hover .tpproduct__img .tp-product-icon {
  opacity: 1;
  visibility: visible;
  bottom: 40%;
}

.tp-product-icon {
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  padding: 0px 10px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.tp-product-icon a {
  height: 50px;
  width: 50px;
  background-color: var(--tp-common-white);
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-weight: 300;
  color: var(--tp-common-black);
  margin: 0px 6px;
}
.tp-product-icon a:hover {
  background-color: var(--tp-theme-1);
  color: var(--tp-common-white);
}

.productdetails {
  padding-left: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .productdetails {
    padding-left: 0;
  }
}
.productdetails__content p {
  font-size: 16px;
  font-weight: 400;
  color: #777B83;
  line-height: 26px;
  padding-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .productdetails__content p br {
    display: none;
  }
}
.productdetails__ratting i {
  color: var(--tp-common-yellow);
  font-size: 14px;
}
.productdetails__ratting span {
  font-size: 14px;
  color: var(--tp-common-black);
  font-weight: 500;
  padding-bottom: 30px;
  display: inline-block;
}
.productdetails__ratting h4 {
  font-weight: 700;
  font-size: 30px;
  color: var(--tp-common-black);
  padding-bottom: 25px;
}
.productdetails__ratting h4 del {
  font-size: 20px;
  font-weight: 500;
  color: #B9B9B9;
}
.productdetails__model {
  padding-bottom: 45px;
}
.productdetails__model h5 {
  font-size: 16px;
  color: var(--tp-common-black);
  font-weight: 500;
  padding-bottom: 10px;
}
.productdetails__model a {
  font-size: 12px;
  color: #777B83;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #F7F8FD;
  height: 25px;
  display: inline-block;
  padding: 0px 20px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.productdetails__model a:hover {
  background-color: var(--tp-theme-1);
  color: var(--tp-common-white);
}

.pd-title {
  font-size: 30px;
  font-weight: 500;
  color: var(--tp-common-black);
  padding-bottom: 10px;
}

/*----------------------------------------*/
/*  19. SHOP CSS START
/*----------------------------------------*/
.productdetails-tabs {
  padding-top: 90px;
  padding-bottom: 90px;
}

.tpshopitem:hover .tpshopitem__thumb img {
  transform: scale(1.2);
}
.tpshopitem:hover .tpshopitem__thumb-icon {
  bottom: 14px;
  opacity: 1;
  visibility: visible;
}
.tpshopitem__thumb {
  border: 1px solid #ECEFF4;
  border-radius: 5px;
}
.tpshopitem__thumb img {
  width: 100%;
  -webkit-transition: all 1s ease-out 0s;
  -moz-transition: all 1s ease-out 0s;
  -ms-transition: all 1s ease-out 0s;
  -o-transition: all 1s ease-out 0s;
  transition: all 1s ease-out 0s;
}
.tpshopitem__thumb-icon {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tpshopitem__thumb-icon a {
  margin: 0 5px;
  height: 45px;
  width: 45px;
  line-height: 45px;
  border: 1px solid var(--tp-theme-blue);
  border-radius: 50%;
  display: inline-block;
  background-color: #0E63FF;
  color: var(--tp-common-white);
}
.tpshopitem__thumb-icon a:hover {
  background-color: var(--tp-theme-pink);
  border: 1px solid var(--tp-theme-pink);
}
.tpshopitem__content p {
  font-size: 18px;
  font-weight: 500;
  color: #777B83;
  margin-bottom: 6px;
}
.tpshopitem__title {
  font-size: 18px;
  font-weight: 500;
  display: block;
}
.tpshopitem__title a {
  background-image: linear-gradient(#0E63FF, #0E63FF), linear-gradient(#0E63FF, #0E63FF);
  background-repeat: no-repeat;
  background-position: 100% 100%, 0% 100%;
  background-size: 0% 1px, 0 1px;
  display: inline;
}
.tpshopitem__title a:hover {
  background-size: 0% 1px, 100% 1px;
  color: var(--tp-theme-blue);
}
.tpshopitem__review i {
  color: #F7931E;
}
.tpshopitem__product-base {
  position: absolute;
  top: 20px;
  left: 30px;
  font-weight: 700;
  font-size: 12px;
  color: var(--tp-common-white);
  padding: 6px 13px;
  background: #FD4766;
  border-radius: 5px;
  line-height: 1;
}

.tpproduct span {
  font-weight: 500;
  font-size: 15px;
}

.tpfilter {
  justify-content: end;
}
@media (max-width: 767px) {
  .tpfilter {
    justify-content: start;
  }
}
.tpfilter span {
  font-weight: 500;
  font-size: 15px;
}
.tpfilter .nice-select {
  padding-left: 5px;
  border: none;
  color: var(--tp-theme-primary);
}

.productthumb img {
  border-radius: 25px;
}
@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .productthumb img {
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .product {
    margin-left: 0;
  }
}
.product__details-content p {
  font-weight: 400;
  font-size: 16px;
  color: #777B83;
}
@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .product__details-content p br {
    display: none;
  }
}
.product-dtitle {
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3;
}
@media (max-width: 767px) {
  .product-dtitle {
    font-size: 24px;
  }
}
.product-dinfo span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #141515;
}
.product-rating a {
  color: #FFDC60;
}
.product-dprice {
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.02em;
}
.product-model-title {
  font-family: "Archivo";
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #0B0B0B;
}
.product-quantity-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #0B0B0B;
}
.product-quantity input {
  color: var(--tp-theme-primary);
  border: none;
  width: 40px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
}
.product-model-list a {
  font-size: 12px;
  font-weight: 600;
  color: #777B83;
  background: #F7F8FD;
  display: inline-block;
  padding: 0 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 15px;
  border-radius: 2px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-model-list a {
    margin-right: 8px;
  }
}
@media (max-width: 767px) {
  .product-model-list a {
    margin-right: 5px;
    padding: 0 6px;
  }
}
.product-model-list a:last-child {
  margin-right: 0;
}
.product-model-list a:hover {
  background: var(--tp-theme-blue);
  color: var(--tp-common-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-product-img {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .tp-product-img img {
    max-width: 100%;
  }
}

.tp-product-quantity .cart-minus, .tp-product-quantity .cart-plus {
  cursor: pointer;
  color: #8D8884;
}
.tp-product-quantity .cart-minus:hover, .tp-product-quantity .cart-plus:hover {
  color: var(--tp-theme-primary);
}
.tp-product-quantity input {
  height: 30px;
  width: 32px;
  font-size: 14px;
  border: none;
  font-weight: 700;
  text-align: center;
}

.product-rating {
  margin-right: 20px;
}
.product-rating a {
  font-size: 16px;
  color: #FFDC60;
}

.product-dinfo {
  display: flex;
}

.product-dprice del {
  font-size: 20px;
  color: #B9B9B9;
}

.pro-details-nav {
  background: #F9FAFB;
  display: inline-block;
  padding: 7px 7px;
}

.pro-details-nav-btn {
  border: none;
}
.pro-details-nav-btn li {
  font-weight: 500;
  font-size: 18px;
  color: var(--tp-text-body);
  position: relative;
}
.pro-details-nav-btn li button span {
  position: relative;
  z-index: 9;
}
.pro-details-nav-btn li button::before {
  content: "";
  height: 100%;
  width: 102%;
  top: 0;
  left: -1px;
  background: #fff;
  position: absolute;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
}
.pro-details-nav-btn li button.active::before {
  opacity: 1;
  visibility: visible;
}
.pro-details-nav-btn li::after {
  position: absolute;
  content: "";
  height: 40px;
  width: 1px;
  background: #DFE3E9;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .pro-details-nav-btn li::after {
    display: none;
  }
}
.pro-details-nav-btn li:last-child::after {
  display: none;
}
@media (max-width: 767px) {
  .pro-details-nav-btn li {
    font-size: 16px;
  }
}
.pro-details-nav-btn li .nav-links {
  padding: 20px 45px;
  border-radius: 5px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-details-nav-btn li .nav-links {
    padding: 10px 20px;
  }
}
@media (max-width: 767px) {
  .pro-details-nav-btn li .nav-links {
    padding: 5px 15px;
  }
}
.pro-details-nav-btn li .nav-links.active {
  background: var(--tp-common-white);
  color: var(--tp-common-black);
}

.tab-para p {
  font-size: 16px;
  line-height: 30px;
}

.comments-box {
  margin-bottom: 50px;
}

.user-rating ul li {
  display: inline-block;
  color: #FFDC60;
}

.comments-text span, .comments-text p {
  font-size: 14px;
  color: #777777;
  line-height: 26px;
}

.comment-title p {
  color: #777777;
  font-size: 16px;
}

.comment-rating span {
  font-weight: 500;
  margin-right: 5px;
}
.comment-rating ul li {
  display: inline-block;
  color: #FFDC60;
}

.comment-input {
  margin-bottom: 30px;
}
.comment-input input, .comment-input textarea {
  height: 55px;
  padding: 0 20px;
  width: 100%;
  font-size: 14px;
  outline: none;
  color: var(--tp-common-black);
  background-color: var(--tp-grey-6);
  border: 1px solid #f7f7f7;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.comment-input input:focus, .comment-input textarea:focus {
  border: 1px solid var(--tp-common-green);
}
.comment-input textarea {
  height: 175px;
  resize: none;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--tp-common-black);
  background-color: var(--tp-grey-6);
}
.comment-input textarea:focus {
  border: 1px solid var(--tp-common-green);
}

.comments-avatar {
  flex: 0 0 auto;
}

.table .add-info {
  font-size: 18px;
  font-weight: 500;
}

.table td {
  padding: 18px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .shop-left-right {
    margin-left: 0;
    margin-right: 0;
  }
}

.tp-comments-title {
  font-size: 24px;
  color: var(--tp-common-black);
  font-weight: 700;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .tp-comments-title {
    font-size: 18px;
  }
}

.avatar-name b {
  color: var(--tp-common-black);
  font-weight: 700;
  text-transform: uppercase;
}

.shop-faq .accordion-header button {
  font-size: 18px;
  padding: 20px 55px 20px 30px;
}
@media (max-width: 767px) {
  .shop-faq .accordion-header button {
    font-size: 14px;
  }
}
.shop-faq .accordion-content p br {
  display: none;
}
.shop-faq .accordion-content p {
  padding: 0 75px 20px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-button a {
    margin-bottom: 10px;
  }
}

.comment-title {
  font-size: 24px;
  color: var(--tp-common-black);
  font-weight: 700;
  text-transform: capitalize;
}

.tp-product-slider-active {
  margin: 0 -15px;
}
.tp-product-slider-active .slick-slide {
  padding: 0px 15px;
}

/*----------------------------------------*/
/*  16. 404 CSS START
/*----------------------------------------*/
.height-404 {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapper-404 {
  height: 100vh;
}

.tp-404-content h4 {
  font-size: 40px;
  font-weight: 600;
  font-size: 30px;
}

.header-grey-bg {
  background-color: #F7F7F7;
}

/*----------------------------------------*/
/*  18. news-letter CSS START
/*----------------------------------------*/
.tp-newsletter-title {
  font-weight: 600;
  font-size: 35px;
  letter-spacing: -0.01em;
  color: var(--tp-common-white);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-newsletter-title {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .tp-newsletter-title {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

.tp-newsletter-wrapper {
  background-color: var(--tp-theme-1);
  padding: 20px 50px;
  border-radius: 10px;
  margin: 30px 0;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .tp-newsletter-wrapper {
    padding: 20px 20px;
  }
}

.tp-newsletter__input input {
  width: 100%;
  height: 60px;
  border: 0;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 240px;
  font-weight: 500;
  font-size: 14px;
  color: var(--tp-common-black);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-newsletter__input input {
    padding-right: 170px;
  }
}
@media (max-width: 767px) {
  .tp-newsletter__input input {
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.tp-newsletter__input input::placeholder {
  color: rgba(34, 34, 34, 0.43);
}
.tp-newsletter__input button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
@media (max-width: 767px) {
  .tp-newsletter__input button {
    position: static;
  }
}

.newsletter-section-box .tp-section-title-sm {
  padding-bottom: 15px;
}
.newsletter-section-box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--tp-grey-4);
}

.newsletter-space .tp-newsletter-wrapper {
  margin: 0;
  background-color: var(--tp-common-black-3);
}

/*----------------------------------------*/
/*  19. contact CSS START
/*----------------------------------------*/
.tp-contact-wrapper {
  padding: 80px 120px;
  background-color: var(--tp-common-white);
  border-radius: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-contact-wrapper {
    padding: 70px 70px;
  }
}
@media (max-width: 767px) {
  .tp-contact-wrapper {
    padding: 20px 20px;
  }
}

.contact-icon {
  margin-right: 20px;
  flex: 0 0 auto;
}

.contact-info-item {
  margin-bottom: 10px;
  border: 1px solid var(--tp-border-1);
  padding: 30px;
  border-radius: 20px;
  margin-right: 120px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info-item {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-item {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .contact-info-item {
    padding: 20px;
    margin-right: 0;
  }
}
.contact-info-item:last-child {
  margin-bottom: 0;
}

.contact-loaction {
  line-height: 18px;
}
.contact-loaction a {
  font-weight: 400;
  font-size: 14px;
  color: var(--tp-common-black);
}

.tp-contact-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.03em;
}

.tpcontact__form input {
  padding: 0px 20px;
  height: 60px;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  color: var(--tp-common-black);
  background-color: var(--tp-grey-6);
  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: 0.3s;
}
.tpcontact__form input:focus {
  border: 1px solid var(--tp-common-green);
}
.tpcontact__form textarea {
  height: 215px;
  width: 100%;
  resize: none;
  padding: 20px 20px;
  font-weight: 400;
  font-size: 15px;
  color: var(--tp-common-black);
  background-color: var(--tp-grey-6);
  border: 1px solid transparent;
  border-radius: 5px;
  outline: 0;
  margin-bottom: 20px;
  transition: 0.3s;
}
.tpcontact__form textarea:focus {
  border: 1px solid var(--tp-common-green);
}

.tp-contact-shape {
  position: absolute;
  top: -75px;
  right: -60px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .tp-contact-shape {
    right: -42px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-contact-shape {
    top: -90px;
    right: -40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-us-sction-box {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .contact-info {
    margin-bottom: 70px;
  }
}

.contact-box {
  background-color: var(--tp-common-deep-blue);
  padding: 70px 60px;
  /* padding-bottom: 100px; */
  border-radius: 20px 0px 20px 0px;
  transform: translateX(100px);
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-box {
    padding: 50px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-box {
    transform: translateX(40px);
    padding: 40px 25px;
  }
}
@media (max-width: 767px) {
  .contact-box {
    transform: translateX(0px);
    padding: 40px 25px;
  }
}
.contact-box-circle {
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: var(--tp-common-white);
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  z-index: 2;
}
@media (max-width: 767px) {
  .contact-box-circle {
    display: none;
  }
}
.contact-box-circle::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background-color: var(--tp-common-deep-blue);
  display: inline-block;
  border-bottom-right-radius: 200px;
  border-top-right-radius: 200px;
  z-index: -1;
}
.contact-box-circle span {
  color: var(--tp-common-black);
  font-weight: 600;
}
.contact-box-circle span:last-child {
  color: var(--tp-common-white);
}
.contact-box__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--tp-common-white);
  margin-bottom: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-box__title {
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-box__title {
    margin-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .contact-box__title {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .contact-box__title br {
    display: none;
  }
}
.contact-box__info-list {
  margin-bottom: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-box__info-list {
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-box__info-list {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .contact-box__info-list {
    margin-bottom: 35px;
  }
}
.contact-box__info-list ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-box__info-list ul li {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .contact-box__info-list ul li {
    margin-bottom: 15px;
  }
}
.contact-box__info-list ul li:last-child {
  margin-bottom: 0;
}
.contact-box__info-list ul li a {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-box__info-list ul li a {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-box__info-list ul li a {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .contact-box__info-list ul li a {
    font-size: 17px;
  }
}
.contact-box__info-list ul li a i {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 25px;
}
@media (max-width: 767px) {
  .contact-box__info-list ul li a i {
    top: 2px;
    font-size: 19px;
  }
}
.contact-box__social ul li {
  display: inline-block;
  margin-right: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-box__social ul li {
    margin-right: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-box__social ul li {
    margin-right: 18px;
  }
}
@media (max-width: 767px) {
  .contact-box__social ul li {
    margin-right: 20px;
  }
}
.contact-box__social ul li a {
  color: var(--tp-common-white);
  font-size: 22px;
}
@media (max-width: 767px) {
  .contact-box__social ul li a {
    font-size: 17px;
  }
}

.contact-wrapper {
  padding: 110px;
  padding-left: 200px;
  border: 1px solid var(--tp-border-1);
  box-shadow: 0px 0px 40px rgba(99, 99, 99, 0.06);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-wrapper {
    padding: 40px;
    padding-left: 170px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-wrapper {
    padding: 40px;
    padding-left: 80px;
  }
}
@media (max-width: 767px) {
  .contact-wrapper {
    padding: 20px;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-us-sction-box {
    margin-bottom: 40px;
  }
}

/*----------------------------------------*/
/*  20. footer CSS START
/*----------------------------------------*/
.footer-bg {
  position: relative;
  background-size: cover;
  padding-bottom: 30px;
}
.footer-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.tp-footer-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--tp-common-white);
  padding-bottom: 20px;
}

.footer-logo{
  width: 150px !important;
 
}

.tp-footer-widget__social {
  position: relative;
}
.tp-footer-widget__social a {
  position: relative;
  display: inline-block;
}
.tp-footer-widget__social a::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.tp-footer-widget__para p{
  font-size:14px;
  color: var(--tp-common-white);
  padding-right: 20px;
}
.tp-footer-widget__list ul.footer-position li {
  padding-left: 40px;
  position: relative;
  margin-bottom: 15px;
}
.tp-footer-widget__list ul.footer-position li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--tp-common-white);
}
.tp-footer-widget__list ul.footer-position li a span {
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 20px;
}
.tp-footer-widget__list ul li {
  padding-bottom: 5px;
}
.tp-footer-widget__list ul li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--tp-common-white);
  transition: 0.3s;
}
.tp-footer-widget__list ul li a:hover {
  color: var(--tp-common-green);
}

.footer-space-one {
  padding-left: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-space-one {
    padding-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .footer-space-one {
    padding-left: 0px;
  }
}

.footer-border-top {
  border-top: 1px solid var(--tp-border-5);
}

.copyright-left p {
  color: var(--tp-common-white);
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright-right-side ul {
    margin-bottom: 30px;
  }
}
.copyright-right-side ul li {
  display: inline-block;
  padding-left: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .copyright-right-side ul li {
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright-right-side ul li {
    padding-left: 0px;
    padding: 0px 15px;
  }
}
@media (max-width: 767px) {
  .copyright-right-side ul li {
    padding-left: 0px;
    padding: 0px 10px;
  }
}
.copyright-right-side ul li:first-child {
  padding-left: 0;
}
.copyright-right-side ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--tp-common-white);
}
.copyright-right-side ul li a:hover {
  color: var(--tp-common-orange);
}

.footer-scroll {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 3;
}
.footer-scroll button {
  height: 40px;
  width: 40px;
  background-color: white;
  border: 2px dashed var(--tp-common-white);
  border-radius: 50%;
}

.footer-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: var(--tp-common-white);
  transition: 0.4s;
  z-index: 99;
}
@media (max-width: 767px) {
  .footer-icons {
    font-size: 20px;
  }
}

.scroll-top {
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  bottom: -10%;
  right: 50px;
  font-size: 16px;
  border-radius: 6px;
  z-index: 99;
  color: var(--tp-common-white);
  text-align: center;
  cursor: pointer;
  background: var(--tp-common-green);
  transition: 1s ease;
  border: none;
}
@media (max-width: 767px) {
  .scroll-top {
    right: 30px;
  }
}

.scroll-top.open {
  bottom: 30px;
}

.scroll-top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}

/* privacy policypage css start */
.privacy-policy-page h2{
  font-size: 50px!important;
}
/* privacy policypage css end*/



/* terms-used css start */
.terms-used-page h2{
  font-size: 50px!important;
}
/* terms-used-css end */




/* Custom */
.search-wrapper a:hover {
  background-color: #25d366;
  color: white;
}

.border-radius-200 {
  border-radius: 200px;
}


