@use '../utils' as *;

/*----------------------------------------*/
/*  08. testimonial CSS START
/*----------------------------------------*/

.tp-testimonial-space{
    padding-top: 130px;
    padding-bottom: 110px;
    @media #{$sm,$xs} {
        padding-top: 0;
    }
}

.tp-testimonial-item{
    background-color: var(--tp-common-yellow-2);
    padding: 50px 60px;
    padding-right: 75px;
    border-radius: 20px;
    position: relative;
    z-index: 3;
    @media #{$lg} {
        padding: 50px 30px;
        padding-right: 30px;
        padding-right: 25px;
    }
    @media #{$xs} {
        padding-right: 0px;
        padding: 20px 20px;
    }
    &__icon{
        margin-bottom: 20px;
        & i{
            box-shadow:(0px 4px 4px rgba(238, 208, 100, 0.26));
            height: 56px;
            width: 56px;
            background-color: var(--tp-common-yellow);
            border-radius: 50%;
            text-align: center;
            line-height: 56px;
            font-weight: 900;
            font-size: 22px;
            color: var(--tp-common-white);
        }
    }
    &__text{
        & p{
            font-weight: 500;
            font-size: 22px;
            line-height: 40px;
            color: var(--tp-common-black);
            padding-bottom: 20px;
            @media #{$xs} {
                font-size: 19px;
                line-height: 29px;
            }
        }
    }
    &__info{
        &-img{
            border: 4px solid var(--tp-common-white);
            border-radius: 50%;
            margin-right: 15px;
            flex: 0 0 auto;
            @media #{$lg} {
                margin-right: 10px;
            }
        }
        &-text{
            & span{
                font-weight: 400;
                font-size: 14px;
                color: var(--tp-common-black);
            }
        }
    }
    &__ratting{
        & span{
            color: var(--tp-common-yellow);
            margin-left: 3px;
        }
    }
}
.client-name{
    font-weight: 600;
    font-size: 20px;
    @media #{$xs} {
        font-size: 16px;
    }
}
.testimonial-bg-color-2{
    background-color: var(--tp-common-green-2);
    & .tp-testimonial-item{
        &__icon{
            & i{
                background-color: var(--tp-common-green);
            }
        }
        &__ratting{
            & span{
                color: var(--tp-common-green);
            }
        }
    }
}
.testimonial-bg-color-3{
    background-color: var(--tp-common-blue);
    & .tp-testimonial-item{
        &__icon{
            & i{
                background-color: var(--tp-theme-1);
            }
        }
        &__ratting{
            & span{
                color: var(--tp-theme-1);
            }
        }
    }
}
.testimonial-border-shape{
    background-position: left top;
    background-repeat: no-repeat;
}
.tp-test-title{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
    @media #{$md} {
        font-size: 17px;
    }
}
.tp-testi-2{
    background-color: var(--tp-common-black-3);
    padding: 80px 120px;
    @media #{$lg} {
        padding: 55px 55px;
    }
    @media #{$md} {
        padding: 50px 70px;
    }
    @media #{$xs} {
        padding: 20px 20px;
    }
    &__wrapper{
        margin-bottom: 45px;
        @media #{$md,$xs} {
            margin-bottom: 20px;
        }
        &-icon{
            & i{               
              color: #1C9CEA;
              font-size: 40px;
              font-weight: 400;
              @media #{$xs} {
                display: none;
            }
            }
        }
    }
    &__meta{
        &-img{
            flex: 0 0 auto;
            margin-right: 30px;
            & img{
                border: 5px solid var(--tp-common-white);
                border-radius: 50%;
            }
        }
        &-info{
            & span{
                font-weight: 400;
                font-size: 14px;
                color: var(--tp-common-white);
            }
        }
    }
    &__text{
        & p{
            font-weight: 400;
            font-size: 40px;            
            color: #DDDDDD;
            line-height: 1.3;
            font-family: 'Arimo'; 
            @media #{$lg} {
                font-size: 31px;
            }
            @media #{$md} {
                font-size: 27px;
            }
            @media #{$xs} {
                font-size: 21px;
            }
        }
    }
}
.tp-testi-bottom{
    padding: 40px 80px;
    padding-right: 60px;
    width: 33%;
    transition: .4s;
    border-right: 1px solid var(--tp-border-5);
    cursor: pointer;
    @media #{$xl} {
        padding: 30px 40px;
    }
    @media #{$md} {
        padding: 40px 44px;
    }
    @media #{$xs} {
        padding: 20px 20px;
        padding-right: 0px;
    }
    @media #{$sm} {
        padding: 20px 60px;
    }
}
.tp-testi-sm-img{
    & img{
        margin-right: 15px;
        border: 5px solid var(--tp-common-white);
        border-radius: 50%;
    }
}
.tp-test-border-wrapper{
    border: 1px solid var(--tp-border-5);
    border-top: 0;
}
.test-img-active{
    position: relative;
    & button {
        height: 40px;
        width: 40px;
        border: 1px dashed;
        display: inline-block;
        position: absolute;
        font-size: 16px;
        border-radius: 50%;
        background-color: var(--tp-common-black);
        z-index: 9;
        transition: .3s;
        &:hover{
            background-color: var(--tp-common-green);
            color: var(--tp-common-white);
            border-color: var(--tp-common-green) ;
        }
        &.slick-prev{
            position: absolute;
            top: 50px;
            left: -20px;
        }
        &.slick-next{
            position: absolute;
            top: 50px;
            right: -20px;
        }
    }
}
.tp-testi-info-text{
    &.tp-test-title{
        margin-bottom: 10px;
    }
    & span{
        font-weight: 400;
        font-size: 14px;
        color: var(--tp-common-white);
    }
}
.tp-testimonial-three{
    &__content{
        background-color: var(--tp-common-white);
        border-radius: 15px;
        padding: 20px 50px;
        padding-top: 50px;
        min-height: 430px;
        @media #{$md} {
            padding: 20px 45px;
            min-height: 370px;
        }
        @media #{$xs} {
            padding: 20px 22px;
            min-height: 310px;
        }
        &-icon{
            margin-bottom: 50px;
            height: 45px;
            width: 45px;
            border-radius: 50%;
            line-height: 45px;
            text-align: center;
            color: var(--tp-common-white);
            font-weight: 500;
            background-color: var(--tp-common-red);
            display: inline-block;
            @media #{$xs} {
                margin-bottom: 20px;
            }
            &.content-icon-1{
                background-color: var(--tp-common-yellow);
            }
            &.content-icon-2{
                background-color: var(--tp-common-green);
            }
            &.content-icon-3{
                background-color: var(--tp-theme-1);
            }
            &.content-icon-4{
                background-color: var(--tp-common-sky);
            }
            &.content-icon-5{
                background-color: var(--tp-common-orange);
            }
        }
        &-text{
            & p{
                font-weight: 400;
                font-size: 18px;
                line-height: 1.7;
                margin-bottom: 0;
                
            }
        }
    }
    &__user{
        margin-top: -50px;
        &-img{
            margin-bottom: 25px;
        }
        &-content{
            & span{
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
}
.user-title{
    font-weight: 600;
    font-size: 20px;
    color: var(--tp-common-black);
}

.testimonial-slide-wrapper{
    margin-left: -140px;
    margin-right: -140px;
    @media #{$xxxl} {
        margin-left: -120px;
        margin-right: -120px;
    }
    @media #{$md,$xs} {
        margin-left: 0px;
        margin-right: 0px;
    }
    & .slick-slide{
        padding: 0px 15px;
        & img {
            display: inline-block;
        }
    }
}

.test-img-active{
    & .slick-current{
        & .tp-testi-bottom{
            border-bottom: 2px solid var(--tp-common-green);
        }
    }
}