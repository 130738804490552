@use '../utils' as *;

/*----------------------------------------*/
/*  18. news-letter CSS START
/*----------------------------------------*/
.tp-newsletter-title{
    font-weight: 600;
    font-size: 35px;
    letter-spacing: -0.01em;
    color: var(--tp-common-white);
    @media #{$md} {
        margin-bottom: 30px;
    }
    @media #{$xs} {
        font-size: 25px;
        margin-bottom: 20px;
    }
}
.tp-newsletter-wrapper{
    background-color: var(--tp-theme-1);
    padding: 20px 50px;
    border-radius: 10px;
    margin: 30px 0;
    margin-bottom: 40px;
    @media #{$xs} {
        padding: 20px 20px;  
    }
}
.tp-newsletter{
    &__input{
        & input{
            width: 100%;
            height: 60px;
            border: 0;
            border-radius: 5px;
            padding-left: 30px;
            padding-right: 240px;
            font-weight: 500;
            font-size: 14px;
            color: var(--tp-common-black);
            @media #{$lg} {
                padding-right: 170px;
            }
            @media #{$xs} {
                margin-bottom: 20px;
                padding-left: 20px;
                padding-right: 20px;
            }
            &::placeholder{
                color: rgba(34, 34, 34, 0.43);
            }
        }
        & button{
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            @media #{$xs} {
                position: static;
            }
        }
    }
}
.newsletter-section-box{
    & .tp-section-title-sm{
        padding-bottom: 15px;
    }
    & p{
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: var(--tp-grey-4);
    }
}
.newsletter-space{
    & .tp-newsletter-wrapper{
        margin: 0;
        background-color: var(--tp-common-black-3);
    }
}