@use '../utils' as *;

/*----------------------------------------*/
/*  04. hero CSS START
/*----------------------------------------*/
.hero-grideant-bg{
    padding-top: 220px;
    padding-bottom: 90px;
    background-repeat: no-repeat;
    @media #{$xs} {
        padding-top: 170px;
    }
}
.hero-space{
    padding-bottom: 115px;
    @media #{$xs} {
        padding-bottom: 70px;
    }
}
.tp-hero{
    @media #{$md} {
        margin-bottom: 70px;
    }
    &__section-box{
        & .tp-slider-title{
            padding-bottom: 15px;
        }
        & p{
            font-size: 20px;
            line-height: 32px;
            color: var(--tp-common-black-2);
            padding-bottom: 30px;
            @media #{$lg} {
                font-size: 17px;
                line-height: 26px; 
            }
            @media #{$xs} {
                font-size: 16px;
                line-height: 26px; 
            }
        }
    }
    &__wrapper{
        @media #{$xs} {
            flex-wrap: wrap;
            margin-bottom: 40px;
        } 
    }
    &__button{
        margin-right: 50px;
        line-height: 0;
        @media #{$lg} {
            margin-right: 30px;
        }
        @media #{$md} {
            margin-right: 20px;
        }
        @media #{$xs} {
            margin-bottom: 20px;
            margin-right: 20px;
        }
        @media #{$sm} {
            margin-right: 20px;
        }
    }
    &__play-button{
        @media #{$xs} {
            margin-bottom: 20px;
        }
        & a{
            font-weight: 900;
            font-size: 16px;
            height: 70px;
            width: 70px;
            background-color: var(--tp-common-yellow);
            border-radius: 50%;
            text-align: center;
            line-height: 70px;
            display: inline-block;
            color: var(--tp-common-white);
            transition: .3s;
            margin-right: 15px;
            @media #{$lg,$md} {
                font-size: 13px;
                height: 60px;
                width: 60px;
                line-height: 60px;
            }
            @media #{$xs} {
                font-size: 14px;
                height: 55px;
                width: 55px;
                line-height: 55px;
            }
            &:hover{
                background-color: var(--tp-theme-1);
            }
        }
        & span{
            font-weight: 700;
            font-size: 16px;
            line-height: 64px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: var(--tp-common-black); 
        }
    }
    &__thumb{
        mix-blend-mode: luminosity;
        & img{
            color: var(--tp-common-black);
            @media #{$md} {
                width: 100%;
            }
        }
    }
    &__thumb3{
        margin-top: 30px;
        margin-right: -150px;
        margin-bottom: 55px;
        @media #{$xxxl} {
            margin-right: -90px;
        }
        @media #{$xxl,$xl,$lg} {
            margin-right: 0px;
        }
        @media #{$md,$xs} {
            margin-right: 0px;
            margin-top: 0;
        }
        &-shape-1{
            position: absolute;
            top: 35%;
            left: -11%;
            z-index: -1;
            
        }
        &-shape-2{
            position: absolute;
            top: 19%;
            right: -4%; 
            animation: jumpTwo 4s infinite linear;        
        }
    }
}
.tp-slider-title{
    font-weight: 600;
    font-size: 85px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    @media #{$xl} {
        font-size: 65px;
    }
    @media #{$lg} {
        font-size: 55px;
    }
    @media #{$md} {
        font-size: 65px;
    }
    @media #{$xs} {
        font-size: 37px;
    }
}
.tp-slider-sm-title{
    font-weight: 600;
    font-size: 66px;
    letter-spacing: -0.01em;
    @media #{$lg} {
        font-size: 50px;
    }
    @media #{$md} {
        font-size: 60px;
    }
    @media #{$xs} {
        font-size: 34px;
    }
}
.tp-hero-shape-one {
	position: absolute;
	top: 5px;
	right: 20px;
	z-index: 3;
    animation: jumpTwo 4s infinite linear;
}
.tp-hero-shape-two {
    position: absolute;
    bottom: 112px;
    right: 28px;
    z-index: 3;
}
.tp-hero-shape-four {
	position: absolute;
	right: 0;
    animation: rotate2 5s linear infinite;
}
.tp-hero-shape-three {
    position: absolute;
    bottom: 95px;
    left: -60px;
    z-index: 3;
    animation: moving 5s ease-in-out infinite;
    @media #{$xl} {
        bottom: 40px;
        left: -103px;
    }
    @media #{$lg} {
        bottom: 40px;
        left: -130px;
    }
}
.tp-slider-exp-title{
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -0.01em;
    padding-bottom: 15px;
    @media #{$lg} {
        padding-bottom: 5px;
    }
    @media #{$xs} {
        font-size: 25px;
    }
}
.tp-slider-exprience{
    @media #{$md,$xs} {
        margin-bottom: 60px;
    }
    &__content{
        padding-bottom: 20px;
        @media #{$lg} {
            padding-bottom: 5px;
        }
        & p{
            font-style: italic;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: var(--tp-common-black-2);
            padding-right: 140px;
            @media #{$xl} {
                padding-right: 120px;
            }
            @media #{$lg} {
                padding-right: 45px;
            }
            @media #{$xs} {
                padding-right: 0px;
                font-size: 17px;
                line-height: 28px;
            }
        }
    }
    &__img{
        margin-right: 15px;
        flex: 0 0 auto;
        @media #{$xs} {
            margin-right: 7px;
        }
    }
    &__meta{
        & span{
            font-weight: 400;
            font-size: 14px;
            color: var(--tp-common-black-2);
            @media #{$xs} {
                font-size: 13px;
            }
            & i{
                color: var(--tp-common-yellow);
            }
        }
    }
}
.tp-client-name{
    font-weight: 600;
    font-size: 20px;
}
.tp-slider-exp-item{
    background-color: var(--tp-common-white);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
    @media #{$lg} {
        padding: 28px;
    }
    &__icon{
        height: 50px;
        width: 50px;
        background-color: var(--tp-common-yellow);
        border-radius: 50%;
        text-align: center;
        line-height: 50px;
        margin-bottom: 20px;
        & svg{
            color: var(--tp-common-white);
        }
        &.icon-bg-green{
            background-color: var(--tp-common-green);
        }
    }
}
.tp-exp-sm-title{
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: var(--tp-common-black);
    padding-bottom: 20px;
    @media #{$sm} {
        br{
            display: none;
        }
    }
}

.hero-bg-img{
    padding-top: 280px;
    padding-bottom: 130px;
    background-repeat: no-repeat;
    @media #{$xs} {
        padding-top: 170px;
    }
    @media #{$sm} {
        padding-top: 180px;
    }
}
.hero-overlay{
    position: relative;
    &::after{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: #191B1D;
        opacity: 0.7;
    }
}
.hero-section-title-box{
    & h3{
        margin-bottom: 80px;
        @media #{$md} {
            & br{
                display: none;
            }
        }
        @media #{$md} {
            margin-bottom: 40px;
        }
        @media #{$xs} {
            margin-bottom: 40px;
        }
        @media #{$sm} {
            margin-bottom: 40px;
        }
        & span{
            & img{
                border-radius: 106px;
            }
        }
    }
}
.tp-hero-content{
    @media #{$xs} {
        flex-wrap: wrap;
    }
    &__thumb{
        padding-left: 60px;
        @media #{$md} {
            padding-left: 0;
            margin-bottom: 40px;
        }
        @media #{$xs} {
            padding-left: 0;
            margin-bottom: 40px;
        }
        @media #{$sm} {
            padding-left: 0;
            margin-bottom: 40px;
            width: 100%;
        }
        & img{
            border-radius: 106px;
            @media #{$sm} {
                width: 100%;
            }
        }
    }
    &__text{
        padding-left: 20px;
        padding-right: 80px;
        @media #{$xl,$lg} {
            padding-right: 0;
        }
        @media #{$md} {
            padding-right: 0;
            padding-left: 30px;
        }
        @media #{$xs} {
            padding-right: 0;
            padding-left: 0px;
        }
        & p{
            font-weight: 400;
            font-size: 26px;
            line-height: 44px;
            color: var(--tp-common-white);
            @media #{$lg} {
                font-size: 20px;
                line-height: 33px;
                padding-right: 30px;
            }            
            @media #{$md} {
                font-size: 17px;
                line-height: 29px;
            }            
            @media #{$xs} {
                font-size: 20px;
                line-height: 28px;
            }            
        }
    }
}
.scroll-down{
    & .circle{
        &-1,
        &-2,
        &-3{
            border-radius: 50%;
            display: inline-block;
            margin-bottom: 8px;
        }

        &-1{
            height: 15px;
            width: 15px;
            background-color: rgba($color: #fff, $alpha: .1);
        }

        &-2{
            height: 9px;
            width: 9px;
            background-color: rgba($color: #fff, $alpha: .4);
        }

        &-3{
            height: 5px;
            width: 5px;
            background-color: rgba($color: #fff, $alpha: 1);
        }
    }
    & > span{
        margin-top: 2px;
        display: inline-block;
        & i{
            font-size: 35px;
            color: var(--tp-common-orange);
            transform: rotate(180deg);
            
        }
    }
}
.tp-hero-blue-shape{
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 480px;
    background-color: var(--tp-theme-1);
    z-index: -1;
    @media #{$xxl} {
        width: 380px;
    }
    @media #{$xl} {
        width: 340px;
    }
    @media #{$lg} {
        width: 385px;
    }
}
.review-wrapper{
    padding: 20px 15px;
    display: inline-block;
    background-color: var(--tp-common-white);
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    border-radius: 12px;
    position: absolute;
    top: 10%;
    left: -10%;
    animation: jumpTwo 4s infinite linear;
}
.review-wrapper-2{
    padding: 20px 15px;
    display: inline-block;
    background-color: var(--tp-common-white);
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    border-radius: 12px;
    position: absolute;
    bottom: 10%;
    left: -10%; 
    animation: about-circle 5s linear infinite alternate;
}
.reviews-text{
    & span{
        font-weight: 700;
        font-size: 14px;
        color: var(--tp-common-black);
    }
    & p{
        font-weight: 500;
        font-size: 12px;
        color: #818181;
        & span{
            color: var(--tp-common-black);
        }
        & i{
            color: var(--tp-common-yellow);
        }
    }
}
.hero-space-three{
    padding-top: 140px;
}
.hero-thumb-space{
    margin-bottom: 100px;
}