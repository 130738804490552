@use '../utils' as *;

/*----------------------------------------*/
/*  19. contact CSS START
/*----------------------------------------*/

.tp-contact-wrapper{
    padding:80px 120px;
    background-color: var(--tp-common-white);
    border-radius: 20px;
    @media #{$md} {
        padding:70px 70px; 
    } 
    @media #{$xs} {
        padding:20px 20px; 
    } 
}
.contact-icon{
    margin-right: 20px;
    flex: 0 0 auto;
}
.contact-info-item{
    margin-bottom: 10px;
    border: 1px solid var(--tp-border-1);
    padding: 30px;
    border-radius: 20px;
    margin-right: 120px;
    @media #{$lg} {
        margin-right: 0; 
    } 
    @media #{$md} {
        margin-right: 0; 
    } 
    @media #{$xs} {
        padding: 20px;
        margin-right: 0; 
    } 
    &:last-child{
        margin-bottom: 0;
    }
}
.contact-loaction{
    line-height: 18px;
    & a{
        font-weight: 400;
        font-size: 14px;
        color: var(--tp-common-black);
    }
}

.tp-contact-title{
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;  
    letter-spacing: -0.03em;
}

.tpcontact{
    &__form{
        & input{
            padding: 0px 20px;
            height: 60px;
            width: 100%;
            font-weight: 400;
            font-size: 15px;
            color: var(--tp-common-black);
            background-color: var(--tp-grey-6);
            border: 1px solid transparent;
            border-radius: 5px;
            margin-bottom: 20px;
            transition: .3s;
            &:focus{
                border: 1px solid var(--tp-common-green);
            }
        }
        & textarea{
            height: 215px;
            width: 100%;
            resize: none;
            padding:20px 20px;
            font-weight: 400;
            font-size: 15px;
            color: var(--tp-common-black);
            background-color: var(--tp-grey-6);
            border: 1px solid transparent;
            border-radius: 5px;
            outline: 0;
            margin-bottom: 20px;
            transition: .3s;
            &:focus{
                border: 1px solid var(--tp-common-green);
            }
        }
    }
}
.tp-contact-shape{
    position: absolute;
    top: -75px;
    right: -60px;
    @media #{$xxl} {
        right: -42px; 
    }
    @media #{$xl} {
        top: -90px;
        right: -40px;
    }
}
.contact-us-sction-box {
    @media #{$md} {
        margin-bottom: 40px;
    }
}
.contact-info {
    @media #{$md,$xs} {
        margin-bottom: 70px;
    }
}

.contact-box{
    background-color: var(--tp-common-deep-blue);
    padding: 70px 60px;
    padding-bottom: 100px;
    border-radius: 20px 0px 20px 0px;
    transform: translateX(100px);
    position: relative;
    @media #{$lg} {
        padding: 50px 30px;
    }
    @media #{$md} {
        transform: translateX(40px);
        padding: 40px 25px;
    }
    @media #{$xs} {
        transform: translateX(0px);
        padding: 40px 25px;
    }
    &-circle{
        height: 40px;
        width: 40px;
        line-height: 40px;
        background-color: var(--tp-common-white);
        text-align: center;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);
        z-index: 2;
        @media #{$xs} {
            display: none;
        }
        &::before{
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            height: 100%;
            width :50%;
            background-color: var(--tp-common-deep-blue);
            display: inline-block;
            border-bottom-right-radius: 200px;
            border-top-right-radius: 200px;
            z-index: -1;
        }

        & span{
            color: var(--tp-common-black);
            font-weight: 600;
            &:last-child{
                color: var(--tp-common-white);
            }
        }
    }
    &__title{
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: var(--tp-common-white);
        margin-bottom: 100px;
        @media #{$lg} {
            margin-bottom: 60px;
        } 
        @media #{$md} {
            margin-bottom: 35px;
        } 
        @media #{$xs} {
            font-size: 30px;
            margin-bottom: 30px;
            & br{
                display: none;
            }
        } 
    }
    &__info-list{
        margin-bottom: 80px;
        @media #{$lg} {
            margin-bottom: 60px;
        }
        @media #{$md} {
            margin-bottom: 40px;
        }
        @media #{$xs} {
            margin-bottom: 35px;
        }
        & ul{
            & li{
                position: relative;
                padding-left: 40px;
                margin-bottom: 20px;
                @media #{$md} {
                    margin-bottom: 15px;
                } 
                @media #{$xs} {
                    margin-bottom: 15px;
                } 
                &:last-child{
                    margin-bottom: 0;
                }
                & a{
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 23px;                   
                    color: #FFFFFF;
                    @media #{$lg} {
                        font-size: 18px;
                    } 
                    @media #{$md} {
                        font-size: 18px;
                    } 
                    @media #{$xs} {
                        font-size: 17px;
                    } 
                    & i{
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 25px;
                        @media #{$xs} {
                            top: 2px;
                            font-size: 19px;
                        } 
                    }                  
                }
            }
        }
    }
    &__social{
        & ul{
            & li{
                display: inline-block;
                margin-right: 30px;
                @media #{$lg} {
                    margin-right: 25px;
                }
                @media #{$md} {
                    margin-right: 18px;
                }
                @media #{$xs} {
                    margin-right: 20px;
                }
                & a{
                    color: var(--tp-common-white);
                    font-size: 22px;
                    @media #{$xs} {
                       font-size: 17px;
                    }
                }
            }
        }
    }
}
.contact-wrapper{
    padding: 110px;
    padding-left: 200px;
    border: 1px solid var(--tp-border-1) ;
    box-shadow: 0px 0px 40px rgba(99, 99, 99, 0.06);
    @media #{$lg} {
        padding: 40px;
        padding-left: 170px;
    }
    @media #{$md} {
        padding: 40px;
        padding-left: 80px;
    }
    @media #{$xs} {
        padding: 20px;
        padding-left: 20px;
    }
}

.contact-us-sction-box {
    @media #{$lg} {
        margin-bottom: 40px;
    }
}