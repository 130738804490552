@use '../utils' as *;

.tp-btn{
	display: inline-block;
	color: var(--tp-common-white);
	background: var(--tp-theme-1);
	border-radius: 10px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	padding: 0 50px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	text-transform: uppercase;
	transition: .3s;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	transform: rotate(-0.48deg);
	box-shadow:0px 9px 14px rgba(89, 86, 233, 0.19);
	@media #{$lg} {
		height: 55px;
		line-height: 55px;
		padding: 0 30px;
		font-size: 14px;
	}
	@media #{$md} {
		height: 55px;
		line-height: 55px;
		padding: 0 30px;
		font-size: 14px;
	}
	@media #{$xs} {
		height: 50px;
		line-height: 50px;
		padding: 0 30px;
		font-size: 14px;
	}
	& i{
		padding-left: 17px;
	}
	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-green);
		transform: translateY(-4px);
		& i {
			animation: tfLeftToRight 0.5s forwards;
		}
	}
}
.tp-btn-sm{
	display: inline-block;
	color: var(--tp-common-white);
	background: var(--tp-common-deep-blue);
	border-radius: 5px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	padding: 0 30px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	text-transform: uppercase;
	transition: .3s;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	& i{
		padding-left: 17px;
	}
	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-green);
		transform: translateY(-4px);
		& i {
			animation: tfLeftToRight 0.5s forwards;
		}
	}
}
.tp-btn-radius{
	display: inline-block;
	color: var(--tp-common-white);
	background: var(--tp-common-deep-blue);
	border-radius: 10px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	padding: 0 50px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	text-transform: uppercase;
	transition: .3s;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	transform: rotate(-0.48deg);
	border-radius: 96px;
	@media #{$lg} {
		height: 55px;
		line-height: 55px;
		padding: 0 30px;
		font-size: 14px;
	}
	@media #{$md} {
		height: 55px;
		line-height: 55px;
		padding: 0 30px;
		font-size: 14px;
	}
	@media #{$xs} {
		height: 50px;
		line-height: 50px;
		padding: 0 30px;
		font-size: 14px;
	}
	& i{
		padding-left: 17px;
	}
	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-green);
		& i {
			animation: tfLeftToRight 0.5s forwards;
		}
	}
}
.tp-btn-yellow{
	display: inline-block;
	color: var(--tp-common-black);
	background: var(--tp-common-yellow);
	border-radius: 5px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	padding: 0 40px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	text-transform: uppercase;
	transition: .3s;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	box-shadow:0px 9px 14px rgba(89, 86, 233, 0.19);
	@media #{$lg} {
		height: 55px;
		line-height: 55px;
		padding: 0 20px;
		font-size: 15px;
	}
	@media #{$xs} {
		height: 55px;
		line-height: 55px;
		padding: 0 40px;
		font-size: 15px;
	}
	& i{
		padding-left: 5px;
	}
	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-black);
		& i {
			animation: tfLeftToRight 0.5s forwards;
		}
	}
}
.tp-btn-black{
	display: inline-block;
	color: var(--tp-common-white);
	background: var(--tp-common-black);
	border-radius: 5px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	padding: 0 40px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	text-transform: uppercase;
	transition: .3s;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	@media #{$lg} {
		height: 55px;
		line-height: 55px;
		padding: 0 20px;
		font-size: 15px;
	}
	@media #{$xs} {
		height: 55px;
		line-height: 55px;
		padding: 0 40px;
		font-size: 15px;
	}
	& i{
		padding-left: 7px;
	}
	&:hover{
		color: var(--tp-common-white);
		background: var(--tp-theme-1);
		& i {
			animation: tfLeftToRight 0.5s forwards;
		}
	}
}
.tp-btn-green{
	display: inline-block;
	color: var(--tp-common-white);
	background: var(--tp-common-green);
	border-radius: 100px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	padding: 0 30px;
	z-index: 1;
	overflow: hidden;
	transition: .3s;
	font-weight: 700;
	font-size: 15px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	&:hover{
		color: var(--tp-theme-1);
		background-color: var(--tp-common-white);
		& i {
			animation: tfLeftToRight 0.5s forwards;
		}
	}
}
.tp-btn-orange{
	display: inline-block;
	color: var(--tp-common-white);
	background: var(--tp-common-orange);
	border-radius: 100px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	padding: 0 30px;
	z-index: 1;
	overflow: hidden;
	transition: .3s;
	font-weight: 700;
	font-size: 15px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	&:hover{
		color: var(--tp-theme-1);
		background-color: var(--tp-common-white);
		& i {
			animation: tfLeftToRight 0.5s forwards;
		}
	}
}
.tp-btn-orange-radius{
	display: inline-block;
	color: var(--tp-common-white);
	background: var(--tp-common-orange);
	border-radius: 100px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	padding: 0 30px;
	z-index: 1;
	overflow: hidden;
	transition: .3s;
	font-weight: 700;
	font-size: 15px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-green);
		& i {
			animation: tfLeftToRight 0.5s forwards;
		}
	}
}
.tp-btn-radius-2{
	display: inline-block;
	color: var(--tp-common-white);
	background: var(--tp-theme-1);
	border-radius: 100px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	padding: 0 30px;
	z-index: 1;
	overflow: hidden;
	transition: .3s;
	font-weight: 700;
	font-size: 15px;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-green);
		& i {
			animation: tfLeftToRight 0.5s forwards;
		}
	}
}
.tp-btn-border{
	display: inline-block;
	color: var(--tp-common-white);
	text-align: center;
	height: 70px;
	line-height: 66px;
	padding: 0 50px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	transition: .3s;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	border: 2px dashed var(--tp-border-5);
	border-radius: 110px;
	@media #{$xl} {
		height: 60px;
		line-height: 60px;
		padding: 0 20px;
		font-size: 14px;
	}
	@media #{$lg,$md} {
		height: 55px;
		line-height: 55px;
		padding: 0 30px;
		font-size: 14px;
	}
	@media #{$xs} {
		height: 55px;
		line-height: 55px;
		padding: 0 40px;
		font-size: 15px;
	}
	& i{
		padding-left: 17px;
	}
	&:hover{
		color: var(--tp-common-white);
		background-color: var(--tp-common-green);
		border: 2px dashed var(--tp-common-green);
		& i {
			animation: tfLeftToRight 0.5s forwards;
		}
	}
}

.tp-link {
    & a {
        position: relative;
        & svg {
            position: relative;
            z-index: 2;
		    margin-right: 20px;
        }
        &::after {
            position: absolute;
            content: "";
            height: 35px;
            width: 35px;
			background-color: rgba($color: #EED064, $alpha: 0.15);
            border-radius: 50%;
            transition: .5s;
            top: -8px;
            left: 10px;
        }
        &::before {
            position: absolute;
            content: "";
            height: 35px;
            width: 35px;
			background-color: var(--tp-common-yellow);
            border-radius: 50%;
            transition: .3s;
            top: -8px;
            left: 10px;
            transform: scale(.3);
            opacity: 0;
            visibility: hidden;
        }
		& span {
            font-weight: 700;
            font-size: 14px;          
            letter-spacing: 0.15em;
            text-transform: uppercase;
            transition: .3s;
        }
        &:hover {
            & span{
                color: var(--tp-common-yellow);
            }
            &::before {
                transform: scale(1);
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.tp-link-green{
    & a{
        &::after {
            background-color: rgba($color: #33CC79, $alpha: 0.15);
        }
        &::before {
            background-color: var(--tp-common-green);
        }
        &:hover {
            & span{
                color: var(--tp-common-green);
            }
        }
    }  
}
.tp-link-border {
    & a {
		position: relative;
        & span {
            font-weight: 700;
            font-size: 12px;
            letter-spacing: 0.135em;
            text-transform: uppercase;
            margin-right: 15px;
            transition: .3s;
			color: var(--tp-common-white);
        }
        & svg {
            position: relative;
			color: var(--tp-common-white);
            z-index: 2;
			transition: .3s;
        }
        &:hover {
            & span{
                color: var(--tp-common-green);
            }
			& svg{
				color: var(--tp-common-green);
			}
			&::before{
				border: 1px dashed var(--tp-common-green);
			}
        }
		&::before{
			content: '';
			position: absolute;
			right: -10px;
			top: -6px;
			height: 30px;
			width: 30px;
			border: 1px dashed var(--tp-border-6);
			border-radius: 50%;
			transition: .3s;
		}
    }
}
