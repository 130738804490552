@use '../utils' as *;

/*---------------------------------------
         Background color
-----------------------------------------*/

.grey-bg {
	background: var(--tp-grey-6);
}
.white-bg {
	background: var(--tp-common-white);
}
.black-bg {
	background: var(--tp-common-black);
}
.theme-bg {
	background: var(--tp-theme-1);
}
