@use '../utils' as *;

/*----------------------------------------*/
/*  07. case CSS START
/*----------------------------------------*/

.case-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.case-space{
    padding-top: 130px;
    padding-bottom: 110px;
    @media #{$md} {
        padding-bottom: 0px;
    }
    @media #{$sm,$xs} {
        padding-top: 0px;
    }
}
.tp-case-thumb{
    border-radius: 20px;
    border: 5px solid var(--tp-common-white);
    &:hover{
        & img{
            transform: scale(1.1);
        }
    }
    & img{
        border-radius: 15px;
        width: 100%;
        transition: .7s;
    }
}
.tp-case-title{
    font-weight: 500;
    font-size: 32px;
    letter-spacing: -0.01em;
    color: var(--tp-common-white);
    padding-bottom: 15px;
    transition: .3s;
    @media #{$xs} {
       font-size: 21px;
    }
    & a{
        &:hover{
            color: var(--tp-common-green);
        }
    }
    @media #{$md} {
        font-size: 25px;
        padding-bottom: 5px;
    }
}
.tp-case-item{
    &__content{
        margin-top: 50px;
    }
    &:hover{
        & .tp-case-item__thumb{
            border-radius: 20px;
            & img{
                transform: scale(1.1);
                border-radius: 20px;
            }
        }
    }
    &__thumb{
        border-radius: 20px;
        & img{
            width: 100%;
            border-radius: 20px;
            transition: .9s;
        }
    }
}
.tp-case-subtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 14px; 
    color: #DDDDDD;
    &::before{
        height: 15px;
        width: 15px;
        background-color: var(--tp-common-green);
        border-radius: 50%;
        content: '';
        display: inline-block;
        transform: translateY(2px);
        margin-right: 5px;
    }
}
.tp-case-slider-active{
    & .slick-slide{
        padding: 0px 15px;
    }
}
.tp-case-wrapper{
    margin-left: -300px;
    margin-right: -300px;
    @media #{$xs} {
        margin-left: 0px;
        margin-right: 0px;
    }
}
.tp-case-active{
    & .slick-slide{
        padding: 0px 15px;
    }
}
.case-grident-bg{
    background: linear-gradient(180deg, #F7F7F7 26.29%, rgba(247, 247, 247, 0) 79.02%);
}
.tp-circle-text{
    & a{
        font-weight: 500;
        font-size: 15px;
        color: var(--tp-common-white);
        line-height: 1.3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) scale(.7);
        height: 110px;
        width: 110px;
        background-color: var(--tp-common-black);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transition: .4s;
    }
}
.tp-case-active{
    & .tp-case-item{
        border: 5px solid var(--tp-common-white);
        border-radius: 20px;
        &__thumb{
            & img{
                margin-bottom: 0;
            }
        }
    }
}
.tp-case-item{
    margin-bottom: 30px;
    &:hover{
        & .tp-circle-text{
            & a{
                opacity: 1;
                visibility: visible;
                transform: translate(-50%,-50%) scale(1);
            }
        }  
    }
}
.case-icon{
    & a{
        font-size: 20px;
        color: var(--tp-common-white);
        display: block;
        background-color: var(--tp-common-red);
        text-align: center;
        padding: 10px 0;
        margin-bottom: 15px;
        border-radius: 3px;
        &.pinterest{
            background-color: #E40028;
        }
        &.twitter{
            background-color: #1DA1F2;
        }
        &.behance{
            background-color: #0E63FF;
        }
    }
}.case-details-title{
    font-weight: 600;
    font-size: 45px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    text-transform: capitalize;
}
.case-custom-space-1{
    padding-top: 80px;
}
.case-custom-space-2{
    padding-right: 60px;
    border-right: 1px solid var(--tp-border-7);
    @media #{$lg} {
        padding-right: 0px;
        border-right: 0;
    }
    @media #{$md,$xs} {
        padding-right: 0px;
        border-right: 0;
    }
}
.case-custom-space-3{
    padding-left: 80px;
    @media #{$lg} {
        padding-left: 0;
    }
    @media #{$md,$xs} {
        padding-left: 0;
    }
}
.case-sapce-top{
    padding-top: 80px;
}

.case-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    & a{
        height: 80px;
        width: 80px;
        background-color: var(--tp-common-green);
        color: var(--tp-common-white);
        font-size: 28px;
        text-align: center;
        line-height: 60px;
        border-radius: 50%;
        transform: rotate(30deg) scale(.5);
        opacity: 0;
        visibility: hidden;
        transition: .4s;
        &:hover{
            color: var(--tp-common-white);
        }
    }
}
.tp-case-thumb{
    &:hover{
        & .case-icon{
            & a{
                transform: rotate(30deg) scale(1);
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.case-social-icon{
    margin-right: 40px;
    @media #{$md,$xs} {
        margin-right: 0;
    }
    & a{
        display: block;
        padding: 10px 20px;
        background-color: var(--tp-common-green);
        color: var(--tp-common-white);
        text-align: center;
        margin-bottom: 10px;
        border-radius: 5px;
        font-size: 20px;
        &.behance{
            background-color: #0E63FF;
        }
        &.twitter{
            background-color: #1DA1F2;
        }
        &.pinterest{
            background-color: #E40028;
        }
    }
}