@use '../utils' as *;

.tp-custom-accordio {
    & .accordion-items {
        margin-bottom: 20px;
        border: 1px solid var(--tp-border-1);
        border-radius: 10px;
        &:not(.collapsed) {
            background-color: var(--tp-grey-6);
        }
    }

    & .accordion-buttons {
        position: relative;
        width: 100%;
        text-align: left;
        font-weight: 500;
        font-size: 20px;
        padding: 40px 45px;
        color: #212121;
        border-radius: 10px 10px 0 0;
        @media #{$xs} {
            font-size: 17px;
            padding: 28px 20px
        }

        &::after {
            position: absolute;
            content: "\f063";
            font-family: 'Font Awesome 5 Pro';
            background-image: none;
            top: 45px;
            right: 45px;
            color: #B8BCC8;
            width: auto;
            height: auto;
            font-weight: 700;
            font-size: 16px;
            color: var(--tp-theme-1);
            @media #{$xs} {
                top: 33px;
                right: 18px;
            }
        }

        &.collapsed {
            background-color: var(--tp-common-white);
            &::after {
                color: #B8BCC8;
                content: '\f062';
            }
        }
    }
    & .collapsed {
        border-radius: 10px 10px 10px 10px;
    }

    & .accordion-body {
        padding: 20px 45px;
        position: relative;
        z-index: 1;
        border-radius: 0 0px 10px 10px;
        font-size: 16px;

        @media #{$xs} {
            padding: 20px 25px;
        }

        &::before {
            position: absolute;
            content: "";
            height: 2px;
            background-color: var(--tp-grey-1);
            top: 0;
            left: 45px;
            right: 45px;
        }
    }
}

.faq-accordio-border {
    & .accordion-items {
        background-color: var(--tp-common-white);
        border: 1px solid #F5F5F5;
        border-radius: 10px;
    }

}