
@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 50s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-170px * 6))}
}

// Styling
.slider {
	// background: white;
	// box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	// height: 100px;
	// margin: auto;
	overflow:hidden;
	position: relative;
	width: 100%;
    margin: 30px 0;
    padding-top: 30px;
	
	&::before,
	&::after {
		@include white-gradient;
		content: "";
		// height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 14);
	}
	
	.slide {
		// height: 100px;
		width: 270px;
        margin-left: 100px;
	}

    .w-350{
        width: 350px;
    }
}